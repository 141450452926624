import Vue from "vue";

export const ReactiveService = new (class {
  /**
   * @private
   */
  _state = {};

  constructor() {
    Vue.observable(this._state);
  }

  /**
   * Reset all state to its initial values.
   * @returns {void}
   */
  resetState() {
    this._state = {};
  }

  /**
   * Returns a given namespace, if the namespace does not exist it will be created.
   * If data is passed and the data already exists it will be overwrite the existing data.
   *
   * @param {string} namespace
   * @param {object} data
   * @returns {object} reactive
   */
  getReactive(namespace, data = {}) {
    const ns = this._state[namespace];

    if (ns) {
      return ns;
    } else {
      Object.assign(this._state, { [namespace]: data });
      return this.getReactive(namespace);
    }
  }

  /**
   * Set data to a given namespace, if the properties already exists the data will be overwritten.
   *
   * @param {string} namespace
   * @param {object} data
   * @returns {void}
   */
  setReactive(namespace, data) {
    const ns = this.getReactive(namespace);

    if (ns) {
      Object.assign(ns, data);
    }
  }
})();
