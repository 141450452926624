<template>
  <div class="modal is-active">
    <div class="modal-background" />
    <form
      class="modal-card"
      @submit.prevent="onSubmitAddAsset"
      autocomplete="off"
    >
      <div class="modal-card-head">
        <h2 class="modal-card-title">Add asset</h2>
      </div>

      <div class="modal-card-body">
        <div class="field">
          <label class="label" for="manufacturerModel">Asset</label>
          <b-autocomplete
            v-model="manufacturerModel"
            id="manufacturerModel"
            v-bind:data="filteredResources"
            v-bind:custom-formatter="formatResource"
            v-bind:required="!displayAddResourceFields"
            v-on:blur="matchExactManufacturerModel"
            v-on:input="matchExactManufacturerModel"
            type="text"
            placeholder="e.g. DELL XPS 15 9500"
          >
            <template v-slot="props">
              <div class="columns">
                <div class="column">
                  {{ props.option.toString() }}
                  <span v-if="isAdmin">
                    ({{ cOffice(props.option.officeLocation).HRV }})
                  </span>
                </div>
              </div>
            </template>
          </b-autocomplete>
        </div>

        <template v-if="displayAddResourceFields">
          <article class="message is-info">
            <div class="message-header">
              <p>We can't seem to find that asset :(</p>
            </div>
            <div class="message-body">
              <p>
                It seems like we're missing the asset you're trying to add.
                Double-check spelling and such and if the asset still doesn't
                exists use the fields below to enter the details of your new
                computer, mobile phone, and similar thingamajigs.
              </p>
            </div>
          </article>

          <div class="field">
            <label class="label" for="resourceManufacturer">Manufacturer</label>
            <div class="control">
              <input
                class="input"
                id="resourceManufacturer"
                minlength="2"
                placeholder="e.g. DELL"
                ref="manufacturer"
                type="text"
                v-bind:required="displayAddResourceFields"
                v-model="resource.manufacturer"
              />
            </div>
          </div>

          <div class="field">
            <label class="label" for="resourceModel">Model</label>
            <div class="control">
              <input
                class="input"
                id="resourceModel"
                minlength="1"
                placeholder="e.g. XPS 15 9500"
                type="text"
                v-bind:required="displayAddResourceFields"
                v-model="resource.model"
              />
            </div>
          </div>

          <div class="field">
            <label class="label" for="resourceType">Type</label>
            <div class="control">
              <div class="select">
                <select
                  id="resourceType"
                  v-bind:required="displayAddResourceFields"
                  v-model="resource.type"
                >
                  <option value>Select type</option>
                  <option
                    v-for="value in resourceTypes"
                    v-bind:key="value"
                    v-bind:value="value"
                  >
                    {{ getHRVForResourceType(value) }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </template>
        <template>
          <div class="field" v-if="isAdmin">
            <label class="label" for="assignee">Assignee</label>
            <div class="office-asset" v-if="isOffice(assignee)">
              Asset will be assigned to office {{ assignee.HRV }}
            </div>
            <b-autocomplete
              field="displayName"
              placeholder="Auto assigned to you if no one is selected"
              type="text"
              id="assignee"
              v-bind:data="filteredOptions"
              v-model="assigneeFilter"
              v-on:select="onSelectAssignee"
            >
              <template v-slot="props">
                <div class="columns">
                  <div class="column">
                    <div
                      v-if="isOffice(props.option)"
                      class="is-flex is-align-items-flex-start"
                    >
                      <the-icon icon="mdi:location" />
                      <span class="ml-1 is-align-self-center">{{
                        props.option.HRV
                      }}</span>
                    </div>
                    <div v-else class="is-flex is-align-items-flex-start">
                      <the-icon icon="raphael:user" />
                      <span class="ml-1 is-align-self-center">{{
                        props.option.displayName
                      }}</span>
                    </div>
                  </div>
                </div>
              </template>
            </b-autocomplete>
          </div>
        </template>

        <div class="field">
          <label class="label" for="serialNumber">Serial number</label>
          <div class="control">
            <input
              class="input"
              id="serialNumber"
              minlength="4"
              placeholder="e.g. D0G54R34W350M3"
              type="text"
              v-model="asset.serialNumber"
            />
          </div>
        </div>

        <label class="label">QR-Code</label>
        <div class="columns">
          <div class="column is-8">
            <input class="input" id="qrCode" readonly v-model="asset.qr" />
          </div>
          <div class="column">
            <qr-reader @qr-scanned="updateQrField" />
          </div>
        </div>

        <div class="field">
          <label class="label" for="retailer">Retailer</label>
          <b-autocomplete
            v-model="retailerDisplayName"
            id="retailer"
            type="text"
            placeholder="e.g. Dustin"
            v-bind:data="filteredRetailers"
            v-bind:custom-formatter="formatRetailer"
          >
            <template v-slot="props">
              <div class="columns">
                <div class="column">
                  {{ props.option.name }}
                  ({{ cOffice(props.option.officeLocation).HRV }})
                  {{
                    props.option.accountName
                      ? "- " + props.option.accountName
                      : ""
                  }}
                </div>
              </div>
            </template>
          </b-autocomplete>
        </div>

        <div class="field">
          <label class="label" for="articleNumber">Article number</label>
          <div class="control">
            <input
              class="input"
              id="articleNumber"
              placeholder="e.g. 4RT1CL3NUM83R"
              type="text"
              v-model="asset.articleNumber"
            />
          </div>
        </div>

        <div class="field">
          <label class="label" for="orderNumber">Order number</label>
          <div class="control">
            <input
              class="input"
              id="orderNumber"
              placeholder="e.g. 0RD3RNUM83R"
              type="text"
              v-model="asset.orderNumber"
            />
          </div>
        </div>

        <div class="field">
          <label class="label" for="price">Price</label>
          <div class="control">
            <input
              class="input"
              id="price"
              min="0"
              placeholder="e.g. 895.99"
              type="number"
              step="0.01"
              v-model.number="asset.price"
            />
          </div>
        </div>

        <div class="field">
          <label class="label" for="yearsOfWarranty">Years of warranty</label>
          <div class="control">
            <input
              class="input"
              id="yearsOfWarranty"
              max="10"
              min="1"
              placeholder="e.g. 3"
              required
              type="number"
              v-model.number="asset.yearsOfWarranty"
            />
          </div>
        </div>

        <div class="field">
          <label class="label" for="dateOfPurchase">Date of purchase</label>
          <div class="control">
            <input
              class="input"
              id="dateOfPurchase"
              placeholder="e.g. 2010-03-12"
              required
              type="date"
              v-model="asset.dateOfPurchase"
            />
          </div>
        </div>

        <div class="field">
          <label class="label" for="comment">Comment</label>
          <div class="control">
            <textarea
              class="input"
              id="comment"
              minlength="4"
              placeholder="e.g. Username: Jon Snow"
              type="text"
              v-model="asset.comment"
            />
          </div>
        </div>
      </div>

      <div class="modal-card-foot is-justify-content-space-between">
        <div>
          <div class="is-pulled-left">
            <button
              type="submit"
              class="button is-dark"
              @click="setShouldDuplicate(false)"
            >
              Add asset
            </button>
          </div>
          <div class="is-pulled-right ml-4">
            <button
              type="button"
              class="button is-white"
              @click="onClickCancel"
            >
              Cancel
            </button>
          </div>
        </div>
        <div>
          <button
            type="submit"
            class="button is-dark"
            @click="setShouldDuplicate(true)"
          >
            Add and duplicate
          </button>
        </div>
      </div>
    </form>

    <button
      class="modal-close is-large"
      aria-label="close"
      @click="onClickClose"
    />
  </div>
</template>

<script>
import { DateTime } from "luxon";
import QrReader from "@/components/assets/QrReader";
import TheIcon from "@/components/general/TheIcon";
import { OfficesMixin } from "@/mixins/OfficesMixin";
import { RetailersMixin } from "@/mixins/RetailersMixin";
import { Asset, AssetStatus } from "@/models/documentModels/Asset";
import { Office } from "@/models/documentModels/Office";
import {
  getHRVForResourceType,
  Resource,
  ResourceType,
} from "@/models/documentModels/Resource";
import { AlertService } from "@/services/AlertService";
import { AssetsService } from "@/services/AssetsService";
import { AuthService } from "@/services/AuthService";
import { CacheService } from "@/services/CacheService";
import { ModalService } from "@/services/ModalService";
import { ResourcesService } from "@/services/ResourcesService";
import { RetailersService } from "@/services/RetailersService";
import { UsersService } from "@/services/UsersService";
import { ResourcesUtils } from "@/utils/ResourcesUtils";

export default {
  name: "AddAssetModal",
  mixins: [OfficesMixin, RetailersMixin],
  components: { TheIcon, QrReader },
  data() {
    return {
      shouldDuplicate: false,
      manufacturerModel: "",
      selectedAssigneeId: "",
      assigneeFilter: "",
      assignee: null,
      retailerDisplayName: "",

      resource: new Resource({
        manufacturer: "",
        model: "",
        type: "",
        officeLocation: null,
      }),

      asset: new Asset({
        officeLocation: "",
        dateOfPurchase: DateTime.local().toISODate(),
        comment: "",
        serialNumber: "",
        yearsOfWarranty: null,
        status: AssetStatus.ASSIGNED,
        qr: this.uuid ?? null,
        price: null,
        orderNumber: "",
        articleNumber: "",
      }),

      isExactAssetMatch: true,

      resources: [],
      users: [],
      offices: [],
      resourceTypes: ResourceType,
      retailers: [],
    };
  },
  props: {
    uuid: {
      type: String,
      required: false,
      default: null,
    },
  },
  async mounted() {
    this.offices = await this.getAdministeringOffices();
    this.offices.forEach((office) => {
      office.displayName = office.HRV;
      return office;
    });
    this.resource.officeLocation = await UsersService.getUserOffice();
    this.resources = await ResourcesService.getAll();
    this.users = await UsersService.getAll();
    this.retailers = await this.getActiveRetailers();
  },
  computed: {
    isAdmin() {
      return AuthService.isAdmin();
    },
    filteredOptions() {
      const filteredEmployees = this.users.filter((user) =>
        JSON.stringify(user).includes(this.assigneeFilter.toLowerCase()),
      );
      const officeLocations = this.offices.filter((office) =>
        office.HRV.toLowerCase().includes(this.assigneeFilter.toLowerCase()),
      );
      return [...filteredEmployees, ...officeLocations];
    },
    isPartialAssetMatch() {
      return (
        this.manufacturerModel === "" ||
        this.resources.some((resource) =>
          `${resource.toString()} (${this.cOffice(resource.officeLocation).HRV})`
            .toLowerCase()
            .includes(this.manufacturerModel.toLowerCase()),
        )
      );
    },
    displayAddResourceFields() {
      return !this.isPartialAssetMatch || !this.isExactAssetMatch;
    },
    filteredResources() {
      return this.resources.filter((resource) =>
        resource
          .toString()
          .toLowerCase()
          .includes(this.manufacturerModel.toLowerCase()),
      );
    },
    filteredRetailers() {
      return this.retailers.filter((retailer) =>
        this.formatRetailer(retailer)
          .toLowerCase()
          .includes(this.retailerDisplayName.toLowerCase()),
      );
    },
  },
  methods: {
    ...CacheService.inject(),
    getHRVForResourceType,
    /**
     * @param {string} value
     * @returns {Resource}
     */
    getResource(value) {
      const resource = this.resources.find(
        (resource) =>
          (AuthService.isUser()
            ? resource.toString()
            : `${resource.toString()} (${this.cOffice(resource.officeLocation).HRV})`) ===
          value,
      );
      return resource ? resource : new Resource();
    },
    isOffice(object) {
      return object instanceof Office;
    },
    async matchExactManufacturerModel() {
      this.isExactAssetMatch = this.resources.some(
        (resource) =>
          (AuthService.isUser()
            ? resource.toString()
            : `${resource.toString()} (${this.cOffice(resource.officeLocation).HRV})`) ===
          this.manufacturerModel,
      );
    },
    onClickClose() {
      ModalService.close();
    },
    onClickCancel() {
      ModalService.close();
    },
    onSelectAssignee(assignee) {
      if (assignee) {
        this.assignee = assignee;
        this.selectedAssigneeId = assignee.id;
      } else {
        this.assignee = null;
        this.selectedAssigneeId = "";
      }
    },
    formatResource(resource) {
      if (AuthService.isUser()) {
        return resource.toString();
      }
      return `${resource.toString()} (${this.cOffice(resource.officeLocation).HRV})`;
    },
    formatRetailer(retailer) {
      return `${retailer.name} (${this.cOffice(retailer.officeLocation).HRV})`;
    },
    async onSubmitAddAsset() {
      await this.createNewAsset();
      if (this.shouldDuplicate) {
        this.clearFieldsForDuplication();
      } else {
        ModalService.close();
      }
    },
    clearFieldsForDuplication() {
      this.assignee = null;
      this.selectedAssigneeId = "";
      this.assigneeFilter = "";
      this.asset.serialNumber = "";
      this.retailerDisplayName = "";
      this.asset.articleNumber = "";
      this.asset.orderNumber = "";
      this.asset.qr = null;
    },
    setShouldDuplicate(shouldDuplicate) {
      this.shouldDuplicate = shouldDuplicate;
    },
    updateQrField(value) {
      this.asset.qr = value;
    },
    async createNewAsset() {
      let isOfficeAsset = this.isOffice(this.assignee);
      let selectedResource;
      let resourceId;
      let retailerId;
      const user = AuthService.getUser();

      if (this.displayAddResourceFields) {
        const resourceToCreate = new Resource({
          type: this.resource.type,
          manufacturer: this.resource.manufacturer.trim(),
          model: this.resource.model.trim(),
          officeLocation: this.resource.officeLocation,
        });

        const existingResource =
          ResourcesUtils.checkResourceAlreadyExists(resourceToCreate);

        if (existingResource) {
          this.manufacturerModel =
            existingResource.manufacturer + " " + existingResource.model;
          return;
        } else {
          const createdResource =
            await ResourcesService.createOne(resourceToCreate);
          resourceId = createdResource.id;
          this.asset.officeLocation = resourceToCreate.officeLocation;
        }
      } else {
        selectedResource = this.getResource(this.manufacturerModel);
        resourceId = selectedResource.id;
        this.asset.officeLocation = selectedResource.officeLocation;
      }

      const resourceRef = ResourcesService.getDocById(resourceId);
      let userRef;
      if (isOfficeAsset) {
        userRef = null;
      } else if (!AuthService.isAdmin()) {
        userRef = UsersService.getDocById(user.uid);
      } else if (this.selectedAssigneeId) {
        userRef = UsersService.getDocById(this.selectedAssigneeId);
      } else {
        this.asset.status = AssetStatus.NEW;
        userRef = null;
      }

      let retailerRef = null;
      if (this.retailerDisplayName !== "") {
        retailerId = this.filteredRetailers[0].id;
        retailerRef = RetailersService.getDocById(retailerId);
      }

      const assetToCreate = new Asset({
        ...this.asset,
        resourceRef: resourceRef,
        userRef: userRef,
        retailerRef: retailerRef,
        isOfficeAsset: isOfficeAsset,
        officeLocation: isOfficeAsset
          ? this.assignee.toRef()
          : this.asset.officeLocation,
      });

      await AssetsService.createOne(assetToCreate);
      const message = "Asset was created";
      AlertService.alert(message);
    },
  },
};
</script>
