import { and, getDocs, query, where } from "firebase/firestore";
import { Asset } from "@/models/documentModels/Asset";
import {
  // eslint-disable-next-line no-unused-vars
  SupportRequest,
  SupportRequestType,
  SupportRequestStatus,
} from "@/models/documentModels/SupportRequest";
import { AssetsService } from "@/services/AssetsService";
import { AuthService } from "@/services/AuthService";
import { FirestoreService } from "@/services/FirestoreService";
import { UsersService } from "@/services/UsersService";

export class SupportRequestsFirestoreService extends FirestoreService {
  /**
   * @override
   * @returns {firestore.Query<firestore.DocumentData>}
   */
  async getAllQuery() {
    const whereClauses = [];
    if (AuthService.isUser()) {
      whereClauses.push(
        where(
          "requestedByUserRef",
          "==",
          UsersService.getDocById(AuthService.getUserId()),
        ),
      );
    } else if (AuthService.isOfficeAdmin()) {
      const user = await UsersService.getOne(AuthService.getUserId());
      if (user.administeredOffices.length > 1) {
        whereClauses.push(
          where("officeLocation", "in", user.administeredOffices),
        );
      } else {
        whereClauses.push(where("officeLocation", "==", user.officeLocation));
      }
    }
    return query(this.getCollection(), and(...whereClauses));
  }

  /**
   * @override
   * @returns {Promise<C[]>}
   */
  async getAll() {
    if (AuthService.isUser()) {
      return this.getCurrentUserSupportRequests();
    } else if (AuthService.isOfficeAdmin()) {
      const user = await UsersService.getOne(AuthService.getUserId());
      if (user.administeredOffices.length > 1) {
        return await this.getAllWhere(
          "officeLocation",
          "in",
          user.administeredOffices,
        );
      } else {
        return await this.getAllWhere(
          "officeLocation",
          "==",
          user.officeLocation,
        );
      }
    }

    const resolvedDocs = await getDocs(this.getCollection());
    return this.toDocClasses(resolvedDocs).filter((doc) => !doc.isDeleted);
  }

  /**
   * @async
   * @param {string} userId
   * @returns {Promise<SupportRequest[]>}
   */
  async getUserRequests(userId) {
    if (AuthService.isSuperAdmin()) {
      return this.getAllWhere(
        "requestedByUserRef",
        "==",
        UsersService.getDocById(userId),
      );
    } else if (AuthService.isOfficeAdmin()) {
      const user = await UsersService.getOne(AuthService.getUserId());
      if (user.administeredOffices.length > 1) {
        return this.getWithWhereClauses([
          where("requestedByUserRef", "==", UsersService.getDocById(userId)),
          where("officeLocation", "in", user.administeredOffices),
        ]);
      } else {
        return this.getWithWhereClauses([
          where("requestedByUserRef", "==", UsersService.getDocById(userId)),
          where("officeLocation", "==", user.officeLocation),
        ]);
      }
    } else {
      if (userId !== AuthService.getUserId()) {
        return [];
      }
      return this.getCurrentUserSupportRequests();
    }
  }

  /**
   * @async
   * @returns {Promise<SupportRequest[]>}
   */
  async getCurrentUserSupportRequests() {
    let supportRequests = await this.getWithWhereClauses([
      where(
        "requestedByUserRef",
        "==",
        UsersService.getDocById(AuthService.getUserId()),
      ),
    ]);

    /*
     * Filtering by assetRef in Assets is too big of a query to handle for Firestore.
     * Therefore we do the filtering in JS land.
     */
    const assetIds = [
      ...(await AssetsService.getAssets(true)),
      ...(await AssetsService.getLoaners()),
    ].map((asset) => asset.id);
    const supportRequestAssetReferences = (
      await this._getAssetReferences(supportRequests)
    ).filter((supportRequestAssetRef) => {
      return assetIds.includes(supportRequestAssetRef);
    });
    return supportRequests.filter((supportRequest) =>
      supportRequestAssetReferences.includes(supportRequest.assetRef.id),
    );
  }

  /**
   * @private
   * @async
   */
  async _getAssetReferences(supportRequests) {
    return (
      await Promise.all(
        supportRequests.map((supportRequest) =>
          supportRequest.resolveRef(Asset, "assetRef"),
        ),
      )
    ).map((resource) => resource.id);
  }

  /**
   * @private
   * @param {string} assetId
   */
  async _getRequestsByAssetId(assetId) {
    return this.getAllWhere(
      "assetRef",
      "==",
      AssetsService.getDocById(assetId),
    );
  }

  /**
   * Reject support requests linked with the Asset id
   * @param {string} assetId
   */
  async deleteAllRequestsByAssetId(assetId) {
    const supportRequests = await this._getRequestsByAssetId(assetId);
    supportRequests.map((request) => this.deleteOne(request.id));
  }

  isApprovedDeletionRequest(supportRequest) {
    return (
      supportRequest.type === SupportRequestType.ASSET_DELETION &&
      supportRequest.status === SupportRequestStatus.APPROVED
    );
  }
}
