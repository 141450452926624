import { CacheService } from "@/services/CacheService";
import { RetailersService } from "@/services/RetailersService";

export const RetailersMixin = {
  methods: {
    doesRetailerExist(isUpdate = false) {
      return CacheService.getRetailers().find(
        (retailer) =>
          retailer.name.toLowerCase() === this.name.toLowerCase() &&
          retailer.officeLocation.id === this.officeLocation.id &&
          retailer.accountName.toLowerCase() === this.accountName &&
          (!isUpdate || retailer.id !== this.retailer.id),
      );
    },
    async getActiveRetailers() {
      const retailers = await RetailersService.getAll();
      return retailers.filter((retailer) => retailer.isActive);
    },
  },
};
