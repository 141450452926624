import { DocumentResolver } from "@/models/documentModels/DocumentResolver";

export class OfficeGroup extends DocumentResolver {
  /**
   * @param {{
   *  id: string;
   *  isDeleted: boolean,
   *  groupName: string;
   *  offices: firestore.DocumentReference<firestore.DocumentData>[];
   * }} parameters
   */
  constructor({
    id = "",
    isDeleted = false,
    groupName = "",
    offices = [],
  } = {}) {
    super();
    this.id = id;
    this.isDeleted = isDeleted;
    this.groupName = groupName;
    this.offices = offices;
  }
}
