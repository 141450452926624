// eslint-disable-next-line no-unused-vars
import { KeyStatus } from "@/models/documentModels/Key";

export class KeysFilter {
  /**
   *
   * @param {{
   * employeeSearch: string;
   * statusSearch: KeyStatus[];
   * locationSearch: Office[]
   * }} parameters
   */
  constructor({
    employeeSearch = "",
    statusSearch = [KeyStatus.ASSIGNED, KeyStatus.UNASSIGNED],
    locationSearch = [],
  } = {}) {
    this.employeeSearch = employeeSearch;
    this.statusSearch = statusSearch;
    this.locationSearch = locationSearch;
  }
}
