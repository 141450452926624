import { getAuth, onAuthStateChanged } from "firebase/auth";
import Vue from "vue";
import App from "@/App";
import InvalidDomainApp from "@/InvalidDomainApp";
import LoadingApp from "@/LoadingApp";
import SignInApp from "@/SignInApp";
import router from "@/router";
import { AuthService } from "@/services/AuthService";
import { CacheService } from "@/services/CacheService";
import { UsersService } from "@/services/UsersService";
import { AuthUtils } from "@/utils/AuthUtils";

function render(app, router = undefined) {
  new Vue({
    router,
    render: (h) => h(app),
  }).$mount("#app");
}

export function createApp(renderer = render) {
  renderer(LoadingApp);

  const auth = getAuth();
  onAuthStateChanged(auth, async (user) => {
    if (user) {
      const userData = await UsersService.getOne(user.uid);
      if (
        user.email.endsWith("softhouse.se") &&
        !userData.disabled &&
        !userData.isDeleted
      ) {
        await AuthUtils.waitUntilUserHasRole();
        await CacheService.create();
        renderer(App, router);

        if (AuthService.isAdmin()) {
          router.replace({ name: "Assets" }).catch(() => {});
        } else {
          router
            .replace({
              name: "User",
              params: { userId: AuthService.getUserId() },
            })
            .catch(() => {});
        }
      } else {
        renderer(InvalidDomainApp, router);
      }
    } else {
      CacheService.destroy();
      renderer(SignInApp);
    }
  });
}
