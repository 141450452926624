/**
 * @enum {string} CollectionNames
 */
export const CollectionNames = {
  ASSET: "assets",
  CHANGELOG_LEGACY: "changelog",
  CHANGELOG: "changelogs",
  FIRE_WAY: "fireway",
  JETBRAINS_ASSET: "jetbrains-assets",
  KEY: "keys",
  OFFBOARD: "offboard",
  OFFICE_GROUP: "office-groups",
  OFFICE: "offices",
  RESOURCE: "resources",
  RETAILER: "retailers",
  SUPPORT_REQUEST: "support-requests",
  THIRD_PARTY_AUTH: "third-party-auth",
  USER: "users",
};
