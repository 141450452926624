import { query, where } from "firebase/firestore";
// eslint-disable-next-line no-unused-vars
import { KeysFilter } from "@/models/KeysFilter";
import { AuthService } from "@/services/AuthService";
import { FirestoreService } from "@/services/FirestoreService";
import { UsersService } from "@/services/UsersService";
import { filterByUserRef } from "@/utils/UsersUtils";

export class KeysFirestoreService extends FirestoreService {
  /**
   * @override
   * @returns {firestore.Query<firestore.DocumentData>}
   */
  async getAllQuery() {
    const whereClauses = [];
    if (AuthService.isUser()) {
      whereClauses.push(
        where(
          "userRef",
          "==",
          UsersService.getDocById(AuthService.getUserId()),
        ),
      );
    } else if (AuthService.isOfficeAdmin()) {
      const user = await UsersService.getOne(AuthService.getUserId());
      if (user.administeredOffices.length > 1) {
        whereClauses.push(
          where("officeLocation", "in", user.administeredOffices),
        );
      } else {
        whereClauses.push(where("officeLocation", "==", user.officeLocation));
      }
    }
    return query(this.getCollection(), ...whereClauses);
  }

  async getKeys() {
    if (AuthService.isAdmin()) {
      return this.getAll();
    } else {
      return this.getCurrentUserKeys();
    }
  }

  /**
   @async
   @param {string} userId
   @returns {Promise<Key[]>}
   */
  async getUserKeys(userId) {
    if (AuthService.isSuperAdmin()) {
      return this.getAllWhere("userRef", "==", UsersService.getDocById(userId));
    } else if (AuthService.isOfficeAdmin()) {
      const user = await UsersService.getOne(AuthService.getUserId());
      if (user.administeredOffices.length > 1) {
        return this.getWithWhereClauses([
          where("userRef", "==", UsersService.getDocById(userId)),
          where("officeLocation", "in", user.administeredOffices),
        ]);
      } else {
        return this.getWithWhereClauses([
          where("userRef", "==", UsersService.getDocById(userId)),
          where("officeLocation", "==", user.officeLocation),
        ]);
      }
    } else {
      if (userId !== AuthService.getUserId()) {
        return [];
      }
      return this.getCurrentUserKeys();
    }
  }

  /**
   * @async
   * @returns {Promise<Key[]>}
   */
  async getCurrentUserKeys() {
    return this.getAllWhere(
      "userRef",
      "==",
      UsersService.getDocById(AuthService.getUserId()),
    );
  }

  /**
   * @param {string} serialNumber
   */
  async getKeysBySerialNumber(serialNumber) {
    if (AuthService.isSuperAdmin()) {
      return this.getAllWhere("serialNumber", "==", serialNumber);
    } else if (AuthService.isOfficeAdmin()) {
      const user = await UsersService.getOne(AuthService.getUserId());
      if (user.administeredOffices.length > 1) {
        return this.getWithWhereClauses([
          where("serialNumber", "==", serialNumber),
          where("officeLocation", "in", user.administeredOffices),
        ]);
      } else {
        return this.getWithWhereClauses([
          where("serialNumber", "==", serialNumber),
          where("officeLocation", "==", user.officeLocation),
        ]);
      }
    } else {
      return this.getWithWhereClauses([
        where("serialNumber", "==", serialNumber),
        where(
          "userRef",
          "==",
          UsersService.getDocById(AuthService.getUserId()),
        ),
      ]);
    }
  }

  /**
   * @param {string} officeLocation
   */
  async getOfficeKeys(officeLocation) {
    return this.getAllWhere("officeLocation", "==", officeLocation);
  }

  /**
   * @param {KeysFilter} filter
   * @returns {Promise<KeysFilter>}
   */
  async getAllFromKeysFilter(filter) {
    const whereClauses = [];

    if (AuthService.isUser()) {
      whereClauses.push(
        where(
          "userRef",
          "==",
          UsersService.getDocById(AuthService.getUserId()),
        ),
      );
    } else if (!AuthService.isSuperAdmin()) {
      const officeLocation = await UsersService.getUserOffice();
      whereClauses.push(where("officeLocation", "==", officeLocation));
    }

    if (filter.locationSearch.length > 0) {
      const officeObjectArray = filter.locationSearch.map((office) =>
        office.toRef(),
      );
      whereClauses.push(where("officeLocation", "in", officeObjectArray));
    }

    if (filter.statusSearch.length > 0) {
      whereClauses.push(where("status", "in", filter.statusSearch));
    }

    let keys = await this.getWithWhereClauses(whereClauses);

    if (filter.employeeSearch) {
      keys = await filterByUserRef(filter.employeeSearch, keys);
    }

    return keys;
  }
}
