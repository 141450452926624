import { Asset } from "@/models/documentModels/Asset";
import { Office } from "@/models/documentModels/Office";
import { Resource } from "@/models/documentModels/Resource";
import { Retailer } from "@/models/documentModels/Retailer";
import { SupportRequest } from "@/models/documentModels/SupportRequest";
import { User } from "@/models/documentModels/User";
import { AssetsService } from "@/services/AssetsService";
import { OfficesService } from "@/services/OfficesService";
import { ResourcesService } from "@/services/ResourcesService";
import { RetailersService } from "@/services/RetailersService";
import { SupportRequestsService } from "@/services/SupportRequestsService";
import { UsersService } from "@/services/UsersService";

function toObjectMap(array) {
  return array.reduce(
    (previousValue, currentValue) => ({
      ...previousValue,
      [currentValue.id]: currentValue,
    }),
    {},
  );
}

class CacheServiceClass {
  /**
   * @private
   */
  _assets = [];
  /**
   * @private
   */
  _assetMap = {};

  /**
   * @private
   */
  _loanerAssets = [];

  /**
   * @private
   */
  _loanerAssetMap = {};

  /**
   * @private
   */
  _resources = [];
  /**
   * @private
   */
  _resourceMap = {};

  /**
   * @private
   */
  _users = [];
  /**
   * @private
   */
  _userMap = {};

  /**
   * @private
   */
  _offices = [];
  /**
   * @private
   */
  _officeMap = {};

  /**
   * @private
   */
  _supportRequests = [];
  /**
   * @private
   */
  _supportRequestMap = {};

  /**
   * @private
   */
  _retailers = [];
  /**
   * @private
   */
  _retailersMap = {};

  async create() {
    await this.fetchAll();
    this.setupObservers();
  }

  destroy() {
    this.removeObservers();
  }

  async fetchAll() {
    return Promise.all([
      this.fetchAssets(),
      this.fetchLoanerAssets(),
      this.fetchResources(),
      this.fetchUsers(),
      this.fetchOffices(),
      this.fetchSupportRequests(),
      this.fetchRetailers(),
    ]);
  }

  async fetchAssets() {
    this._assets = await AssetsService.getAssets();
    this._assetMap = toObjectMap(this._assets);
  }

  async fetchLoanerAssets() {
    this._loanerAssets = await AssetsService.getLoaners();
    this._loanerAssetMap = toObjectMap(this._loanerAssets);
  }

  async fetchResources() {
    this._resources = await ResourcesService.getAll();
    this._resourceMap = toObjectMap(this._resources);
  }

  async fetchUsers() {
    this._users = await UsersService.getAll();
    this._userMap = toObjectMap(this._users);
  }

  async fetchOffices() {
    this._offices = await OfficesService.getAll();
    this._officeMap = toObjectMap(this._offices);
  }

  async fetchSupportRequests() {
    this._supportRequests = await SupportRequestsService.getAll();
    this._supportRequestMap = toObjectMap(this._supportRequests);
  }

  async fetchRetailers() {
    this._retailers = await RetailersService.getAll();
    this._retailersMap = toObjectMap(this._retailers);
  }

  setupObservers() {
    AssetsService.addObserver(this.fetchAssets.bind(this));
    AssetsService.addObserver(this.fetchLoanerAssets.bind(this));
    ResourcesService.addObserver(this.fetchResources.bind(this));
    UsersService.addObserver(this.fetchUsers.bind(this));
    OfficesService.addObserver(this.fetchOffices.bind(this));
    SupportRequestsService.addObserver(this.fetchSupportRequests.bind(this));
    RetailersService.addObserver(this.fetchRetailers.bind(this));
  }

  removeObservers() {
    AssetsService.clearObservers();
    ResourcesService.clearObservers();
    OfficesService.clearObservers();
    UsersService.clearObservers();
    SupportRequestsService.clearObservers();
    RetailersService.clearObservers();
  }

  getAssets() {
    return this._assets;
  }

  getLoanerAssets() {
    return this._loanerAssets;
  }

  getResources() {
    return this._resources;
  }

  getUsers() {
    return this._users;
  }

  getOffices() {
    return this._offices;
  }

  getSupportRequests() {
    return this._supportRequests;
  }

  getRetailers() {
    return this._retailers;
  }

  /**
   * @param {{
   *  id: string;
   * }} parameters
   */
  getAsset(parameters) {
    const id = (parameters && parameters.id) ?? "";
    return this._assetMap[id] || new Asset();
  }

  /**
   * @param {{
   *  id: string;
   * }} parameters
   */
  getLoanerAsset(parameters) {
    const id = (parameters && parameters.id) ?? "";
    return this._loanerAssetMap[id] || new Asset();
  }

  /**
   * @param {{
   *  id: string;
   * }} parameters
   */
  getResource(parameters) {
    const id = (parameters && parameters.id) ?? "";
    return this._resourceMap[id] || new Resource();
  }

  /**
   * @param {{
   *  id: string;
   * }}  parameters
   */
  getUser(parameters) {
    const id = (parameters && parameters.id) ?? "";
    return this._userMap[id] || new User();
  }

  /**
   * @param {{
   *  id: string;
   * }} parameters
   */
  getOffice(parameters) {
    const id = (parameters && parameters.id) ?? "";
    return this._officeMap[id] || new Office();
  }

  /**
   * @param {{
   *  id: string;
   * }} parameters
   */
  getSupportRequest(parameters) {
    const id = (parameters && parameters.id) ?? "";
    return this._supportRequestMap[id] || new SupportRequest();
  }

  /**
   * @param {{
   * id: string;
   * }} parameters
   */
  getRetailer(parameters) {
    const id = (parameters && parameters.id) ?? "";
    return this._retailersMap[id] || new Retailer();
  }

  /**
   * inject() is intended to be used in components for easier access to methods. Returns an object containing class methods. Each method will be prefixed with `c` to not conflict with other methods.
   *
   * Example:
   *
   * ```
   * methods: {
   *   ...CacheService.inject()
   * }
   * ```
   */
  inject() {
    return {
      cAsset: this.getAsset.bind(this),
      cLoanerAsset: this.getLoanerAsset.bind(this),
      cResource: this.getResource.bind(this),
      cUser: this.getUser.bind(this),
      cOffice: this.getOffice.bind(this),
      cOffices: this.getOffices.bind(this),
      cSupportRequest: this.getSupportRequest.bind(this),
      cRetailer: this.getRetailer.bind(this),
    };
  }
}

export const CacheService = new CacheServiceClass();
