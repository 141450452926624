// eslint-disable-next-line no-unused-vars
import { AssetsFilter } from "@/models/AssetsFilter";

export class JetbrainsAssetsFilter {
  /**
   * @type {string[]}
   */
  status;

  /**
   * @type {string}
   */
  assetName;

  /**
   * @type {string}
   */
  fromDateOfPurchase;

  /**
   * @type {string}
   */
  toDateOfPurchase;

  /**
   * @type {string}
   */
  employeeName;

  /**
   * @type {string}
   */
  assetType;

  /**
   * @type {Office[]}
   */
  locationSearch;

  /**
   *
   * @param {AssetsFilter} assetsFilter
   */
  constructor({
    statusSearch = ["ASSIGNED", "UNASSIGNED"],
    resourceSearch = "",
    fromDateOfPurchaseSearch = "",
    toDateOfPurchaseSearch = "",
    assigneeSearch = "",
    resourceType = "",
    locationSearch = [],
  } = {}) {
    this.status = statusSearch;
    this.assetName = resourceSearch;
    this.fromDateOfPurchase = fromDateOfPurchaseSearch;
    this.toDateOfPurchase = toDateOfPurchaseSearch;
    this.employeeName = assigneeSearch;
    this.assetType = resourceType;
    this.locationSearch = locationSearch;
  }
}
