import { doc, getFirestore } from "firebase/firestore";
import { CollectionNames } from "@/models/CollectionNames";
import { DocumentResolver } from "@/models/documentModels/DocumentResolver";

export class Office extends DocumentResolver {
  /**
   * @param {{
   *  id: string;
   *  isDeleted: boolean;
   *  CRV: string;
   *  HRV: string;
   * }} parameters
   */
  constructor({ id = "", isDeleted = false, CRV = "", HRV = "" } = {}) {
    super();
    this.id = id;
    this.isDeleted = isDeleted;
    this.CRV = CRV;
    this.HRV = HRV;
  }

  toRef() {
    return doc(getFirestore(), CollectionNames.OFFICE, this.id);
  }
}
