<template>
  <div id="app">
    <router-view :key="$route.fullPath" />

    <the-navbar />
    <the-modal-container />
    <the-alert-container />
  </div>
</template>

<script>
import TheAlertContainer from "@/components/app/TheAlertContainer";
import TheModalContainer from "@/components/app/TheModalContainer";
import TheNavbar from "@/components/app/TheNavbar";
import { addClass } from "@/utils/DomUtils";

export default {
  name: "App",
  components: {
    TheAlertContainer,
    TheNavbar,
    TheModalContainer,
  },
  created() {
    addClass(document.body, "has-navbar-fixed-bottom");
  },
};
</script>
