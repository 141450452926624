<template>
  <button
    class="button nav-add-button is-success is-inverted"
    @click="onClickAddResource"
    title="Add resource"
  >
    <the-icon icon="icons8:plus" />
  </button>
</template>

<script>
import TheIcon from "@/components/general/TheIcon";
import AddResourceModal from "@/components/resources/AddResourceModal";
import { ModalService } from "@/services/ModalService";

export default {
  name: "AddResourceButton",
  components: { TheIcon },
  methods: {
    onClickAddResource() {
      ModalService.open(AddResourceModal);
    },
  },
};
</script>
