import { initializeApp } from "firebase/app";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import Config from "@/config";

export const firebaseConfig = {
  apiKey: "AIzaSyDTkDoOiDKRCMuqst8-9qbnJQ-rIfuh7Vc",
  authDomain: "heaven.softhouselabs.com",
  databaseURL: "https://sitem-8438a.firebaseio.com",
  projectId: "sitem-8438a",
  storageBucket: "sitem-8438a.appspot.com",
  messagingSenderId: "449738208492",
  appId: "1:449738208492:web:b080a7abc4ce386dd9a1c9",
};
const app = initializeApp(firebaseConfig);

// Uncomment to use production data
if (process.env.NODE_ENV === "development") {
  const db = getFirestore(app);
  const auth = getAuth(app);
  const functions = getFunctions(app, Config.REGION);

  connectFirestoreEmulator(db, "localhost", 8081);
  connectAuthEmulator(auth, "http://localhost:9099");
  connectFunctionsEmulator(functions, "localhost", 5001);
}
