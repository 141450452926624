import {
  // eslint-disable-next-line no-unused-vars
  SupportRequestType,
  SupportRequestStatus,
} from "@/models/documentModels/SupportRequest";

export class SupportRequestsFilter {
  /**
   *
   * @param {{
   *  type: SupportRequestType;
   *  status: SupportRequestStatus[];
   *  resourceSearch: string;
   *  requestedBy: string;
   *  leader: string;
   * }} parameters
   */
  constructor({
    type = "",
    status = [SupportRequestStatus.PENDING, SupportRequestStatus.CREATED],
    resourceSearch = "",
    requestedBy = "",
    leader = "",
  } = {}) {
    this.type = type;
    this.status = status;
    this.resourceSearch = resourceSearch;
    this.requestedBy = requestedBy;
    this.leader = leader;
  }
}
