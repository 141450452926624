import { doc, getFirestore } from "firebase/firestore";
import { DateTime } from "luxon";
import { CollectionNames } from "@/models/CollectionNames";
import { DocumentResolver } from "@/models/documentModels/DocumentResolver";
import { Resource } from "@/models/documentModels/Resource";
import { Retailer } from "@/models/documentModels/Retailer";
import { User } from "@/models/documentModels/User";

/**
 * @enum {string} AssetStatus
 */
export const AssetStatus = {
  ASSIGNED: "ASSIGNED",
  UNASSIGNED: "UNASSIGNED",
  RETIRED: "RETIRED",
  RECYCLED: "RECYCLED",
  PURCHASED: "PURCHASED",
  NEW: "NEW",
};

/**
 * Returns the Human Readable Value(HRV) for a given status.
 *
 * @param {AssetStatus} status
 */
export function getHRVForAssetStatus(status) {
  const values = {
    [AssetStatus.ASSIGNED]: "Assigned",
    [AssetStatus.UNASSIGNED]: "Unassigned",
    [AssetStatus.RETIRED]: "Retired",
    [AssetStatus.RECYCLED]: "Recycled",
    [AssetStatus.PURCHASED]: "Purchased",
    [AssetStatus.NEW]: "New",
  };
  return values[status];
}

/**
 * @class Asset
 */
export class Asset extends DocumentResolver {
  /**
   * @param {{
   *  id: string;
   *  articleNumber: string | null;
   *  comment: string;
   *  dateOfPurchase: string;
   *  estimatedReturnDate: string;
   *  guest: Object;
   *  isDeleted: boolean;
   *  isOfficeAsset: boolean;
   *  isThirdPartyAsset: boolean
   *  loaner: boolean;
   *  officeLocation: firestore.DocumentReference<firestore.DocumentData>;
   *  orderNumber: string | null;
   *  price: number | null;
   *  qr: String;
   *  resourceRef: firestore.DocumentReference<firestore.DocumentData>;
   *  retailerRef: firestore.DocumentReference<firestore.DocumentData> | null;
   *  serialNumber: string | null;
   *  status: AssetStatus;
   *  userRef: firestore.DocumentReference<firestore.DocumentData>;
   *  yearsOfWarranty: number;
   * }} parameters
   */
  constructor({
    id = "",
    articleNumber = "",
    comment = "",
    dateOfPurchase,
    estimatedReturnDate = null,
    guest = null,
    isDeleted = false,
    isOfficeAsset = false,
    isThirdPartyAsset = false,
    loaner = false,
    officeLocation,
    orderNumber = "",
    price = null,
    qr,
    resourceRef,
    retailerRef = null,
    serialNumber = "",
    status,
    userRef,
    yearsOfWarranty,
  } = {}) {
    super();
    this.id = id;
    this.articleNumber = articleNumber;
    this.comment = comment;
    this.dateOfPurchase = dateOfPurchase;
    this.estimatedReturnDate = estimatedReturnDate;
    this.guest = guest;
    this.isDeleted = isDeleted;
    this.isOfficeAsset = isOfficeAsset;
    this.isThirdPartyAsset = isThirdPartyAsset;
    this.loaner = loaner;
    this.officeLocation = officeLocation;
    this.orderNumber = orderNumber;
    this.price = price;
    this.qr = qr;
    this.resourceRef = resourceRef;
    this.retailerRef = retailerRef;
    this.serialNumber = serialNumber;
    this.status = status;
    this.userRef = userRef;
    this.yearsOfWarranty = yearsOfWarranty;
  }

  /**
   * Returns the resolved ref data in an array structure.
   *
   * The return value can be destructured like:
   *
   * `const [resource, user, retailer] = await asset.resolveRefs();`
   *
   * @function
   * @async
   * @returns {Promise<[Resource, User, Retailer]>} data
   */
  async resolveRefs() {
    return Promise.all([
      this.resolveRef(Resource, "resourceRef"),
      this.resolveRef(User, "userRef"),
      this.resolveRef(Retailer, "retailerRef"),
    ]);
  }

  toRef() {
    return doc(getFirestore(), CollectionNames.ASSET, this.id);
  }

  getExpiryDate() {
    return DateTime.fromISO(this.dateOfPurchase)
      .plus({
        years: this.yearsOfWarranty,
      })
      .toISODate();
  }
}
