import { where } from "firebase/firestore";
// eslint-disable-next-line no-unused-vars
import { ResourcesFilter } from "@/models/ResourcesFilter";
import { AuthService } from "@/services/AuthService";
import { CacheService } from "@/services/CacheService";
import { ResourcesService } from "@/services/ResourcesService";
import { UsersService } from "@/services/UsersService";
import { commonCharactersAreEqual } from "@/utils/StringUtils";

class ResourcesUtilsClass {
  /**
   *
   * @param {ResourcesFilter} filter
   */
  async getAllFromResourcesFilter(filter) {
    const whereClauses = [];

    if (filter.location.length > 0) {
      const officeObjectArray = filter.location.map((office) => office.toRef());
      whereClauses.push(where("officeLocation", "in", officeObjectArray));
    }
    if (AuthService.isUser()) {
      const user = await UsersService.getOne(AuthService.getUserId());
      whereClauses.push(where("officeLocation", "==", user.officeLocation));
    } else if (AuthService.isOfficeAdmin()) {
      const user = await UsersService.getOne(AuthService.getUserId());
      if (user.administeredOffices.length > 1) {
        whereClauses.push(
          where("officeLocation", "in", user.administeredOffices),
        );
      } else {
        whereClauses.push(where("officeLocation", "==", user.officeLocation));
      }
    }

    if (filter.type) {
      whereClauses.push(where("type", "==", filter.type));
    }

    let resources = await ResourcesService.getWithWhereClauses(whereClauses);

    // ? Perform substring comparison in the client as Firestore do not support this natively.
    if (filter.searchTerm) {
      resources = resources.filter((resource) => {
        const isManufacturerMatch = resource.manufacturer
          .toLowerCase()
          .includes(filter.searchTerm.toLowerCase());
        const isModelMatch = resource.model
          .toLowerCase()
          .includes(filter.searchTerm.toLowerCase());

        return isManufacturerMatch || isModelMatch;
      });
    }

    return resources;
  }

  /**
   *  checks if resource already exists,
   *  if true return the existing resource
   *
   * @param {Resource} newResource
   * @returns {Resource}
   */
  checkResourceAlreadyExists(newResource) {
    const existingResources = CacheService.getResources();
    return existingResources.find((resource) => {
      return (
        commonCharactersAreEqual(
          CacheService.getOffice(resource.officeLocation).CRV,
          CacheService.getOffice(newResource.officeLocation).CRV,
        ) &&
        commonCharactersAreEqual(
          resource.manufacturer,
          newResource.manufacturer,
        ) &&
        commonCharactersAreEqual(resource.model, newResource.model)
      );
    });
  }
}

export const ResourcesUtils = new ResourcesUtilsClass();
