<template>
  <button
    class="button"
    :class="buttonClasses"
    @click="onClickAddAsset"
    title="Add asset"
  >
    <div v-if="isAdmin">
      <the-icon icon="icons8:plus" />
    </div>
    <div v-else>
      <div class="is-hidden-desktop">
        <span class="icon m-0">
          <the-icon icon="icons8:plus" size="30" />
        </span>
      </div>
      <div class="is-hidden-touch">Add asset</div>
    </div>
  </button>
</template>

<script>
import AddAssetModal from "@/components/assets/AddAssetModal";
import TheIcon from "@/components/general/TheIcon";
import { AuthService } from "@/services/AuthService";
import { ModalService } from "@/services/ModalService";

export default {
  name: "AddAssetButton",
  components: { TheIcon },
  computed: {
    isAdmin() {
      return AuthService.isAdmin();
    },
    buttonClasses() {
      return AuthService.isAdmin()
        ? { "nav-add-button": true, "is-success": true, "is-inverted": true }
        : { "is-success": true };
    },
  },
  methods: {
    onClickAddAsset() {
      ModalService.open(AddAssetModal);
    },
  },
};
</script>
