import { where } from "firebase/firestore";
// eslint-disable-next-line no-unused-vars
import { SupportRequestsFilter } from "@/models/SupportRequestsFilter";
import { AuthService } from "@/services/AuthService";
import { CacheService } from "@/services/CacheService";
import { SupportRequestsService } from "@/services/SupportRequestsService";
import { UsersService } from "@/services/UsersService";

class SupportRequestsUtilsClass {
  /**
   *
   * @param {SupportRequestsFilter} filter
   */
  async getAllFromSupportRequestsFilter(filter) {
    const whereClauses = [];

    if (AuthService.isUser()) {
      whereClauses.push(
        where(
          "requestedByUserRef",
          "==",
          UsersService.getDocById(AuthService.getUserId()),
        ),
      );
    } else if (AuthService.isOfficeAdmin()) {
      const user = await UsersService.getOne(AuthService.getUserId());
      if (user.administeredOffices.length > 1) {
        whereClauses.push(
          where("officeLocation", "in", user.administeredOffices),
        );
      } else {
        whereClauses.push(where("officeLocation", "==", user.officeLocation));
      }
    }

    if (filter.status.length > 0) {
      whereClauses.push(where("status", "in", filter.status));
    }

    if (filter.type) {
      whereClauses.push(where("type", "==", filter.type));
    }

    let supportRequests =
      await SupportRequestsService.getWithWhereClauses(whereClauses);

    if (AuthService.isUser()) {
      /*
       * Filtering by assetRef in Assets is too big of a query to handle for Firestore.
       * Therefore we do the filtering in JS land.
       */
      const assetIds = [
        ...CacheService.getAssets().filter((a) => !a.isThirdPartyAsset),
        ...CacheService.getLoanerAssets(),
      ].map((asset) => asset.id);
      const supportRequestAssetReferences = (
        await this.getAssetReferences(supportRequests)
      ).filter((supportRequestAssetRef) => {
        return assetIds.includes(supportRequestAssetRef);
      });
      supportRequests = supportRequests.filter((supportRequest) =>
        supportRequestAssetReferences.includes(supportRequest.assetRef.id),
      );
    }

    if (filter.requestedBy) {
      let users = await CacheService.getUsers();
      users = users.filter((user) => {
        return user.displayName
          .toLowerCase()
          .includes(filter.requestedBy.toLowerCase());
      });
      let supportRequestUserReferences =
        await this.getUserReferences(supportRequests);
      let userIds = users.map((user) => user.id);
      supportRequestUserReferences = supportRequestUserReferences.filter(
        (supportRequestUserRef) => {
          return userIds.includes(supportRequestUserRef);
        },
      );
      supportRequests = supportRequests.filter((s) =>
        supportRequestUserReferences.includes(s.userRef.id),
      );
    }

    if (filter.resourceSearch) {
      const resources = CacheService.getResources().filter((resource) => {
        return (resource.manufacturer + " " + resource.model)
          .toLowerCase()
          .includes(filter.resourceSearch.toLowerCase());
      });
      const assetIds = CacheService.getAssets()
        .filter((asset) =>
          resources.find((resource) => resource.id === asset.resourceRef.id),
        )
        .map((asset) => asset.id);

      const supportRequestAssetReferences = (
        await this.getAssetReferences(supportRequests)
      ).filter((supportRequestAssetRef) => {
        return assetIds.includes(supportRequestAssetRef);
      });
      supportRequests = supportRequests.filter((supportRequest) =>
        supportRequestAssetReferences.includes(supportRequest.assetRef.id),
      );
    }

    if (filter.leader) {
      const userIds = (
        await UsersService.getUsersByLeaderId(filter.leader)
      ).map((user) => {
        return user.id;
      });

      supportRequests = supportRequests.filter((supportRequest) =>
        userIds.includes(supportRequest?.assetUserRef?.id),
      );
    }

    return supportRequests;
  }

  async getUserReferences(supportRequests) {
    return supportRequests
      .map((s) => (s.userRef = CacheService.getUser(s.requestedByUserRef)))
      .map((u) => u.id);
  }

  async getAssetReferences(supportRequests) {
    return supportRequests
      .map((supportRequest) => {
        const asset = CacheService.getAsset(supportRequest.assetRef);
        if (asset.id) {
          return asset;
        }
        return CacheService.getLoanerAsset(supportRequest.assetRef);
      })
      .map((asset) => asset.id);
  }
}

export const SupportRequestsUtils = new SupportRequestsUtilsClass();
