<template>
  <div>
    <button type="button" class="button is-warning" @click="onClickQrButton">
      {{ buttonText }}
    </button>
    <qrcode-stream @decode="onDecode" @init="onInit" v-show="cameraIsActive" />
    <p class="error">{{ error }}</p>
  </div>
</template>

<script>
import { QrcodeStream } from "vue-qrcode-reader";

export default {
  name: "QrReader",
  components: {
    QrcodeStream,
  },
  data() {
    return {
      error: "",
      cameraIsActive: false,
    };
  },
  computed: {
    buttonText() {
      return this.cameraIsActive ? "Deactivate camera" : "Scan QR";
    },
  },
  methods: {
    onDecode(result) {
      const value = result?.split("/")?.pop();
      if (value) {
        this.$emit("qr-scanned", value);
        this.onClickQrButton();
      }
    },
    async onInit(promise) {
      try {
        await promise;
      } catch (error) {
        if (error.name === "NotAllowedError") {
          this.error = "ERROR: you need to grant camera access permisson";
        } else if (error.name === "NotFoundError") {
          this.error = "ERROR: no camera on this device";
        } else if (error.name === "NotSupportedError") {
          this.error = "ERROR: secure context required (HTTPS, localhost)";
        } else if (error.name === "NotReadableError") {
          this.error = "ERROR: is the camera already in use?";
        } else if (error.name === "OverconstrainedError") {
          this.error = "ERROR: installed cameras are not suitable";
        } else if (error.name === "StreamApiNotSupportedError") {
          this.error = "ERROR: Stream API is not supported in this browser";
        }
      }
    },
    onClickQrButton() {
      this.cameraIsActive = !this.cameraIsActive;
    },
  },
};
</script>
