import { and, or, query, where } from "firebase/firestore";
// eslint-disable-next-line no-unused-vars
import { Asset } from "@/models/documentModels/Asset";
import { UserRole } from "@/models/documentModels/User";
import { AuthService } from "@/services/AuthService";
import { FirestoreService } from "@/services/FirestoreService";
import { JetbrainsAssetsService } from "@/services/JetbrainsAssetsService";
import { UsersService } from "@/services/UsersService";

export class AssetsFirestoreService extends FirestoreService {
  /**
   * @override
   * @returns {firestore.Query<firestore.DocumentData>}
   */
  async getAllQuery() {
    const whereClauses = [];
    if (AuthService.isUser()) {
      const officeLocation = await UsersService.getUserOffice();
      whereClauses.push(
        or(
          where(
            "userRef",
            "==",
            UsersService.getDocById(AuthService.getUserId()),
          ),
          and(
            where("loaner", "==", true),
            where("officeLocation", "==", officeLocation),
          ),
          and(
            where("isOfficeAsset", "==", true),
            where("officeLocation", "==", officeLocation),
          ),
        ),
      );
    } else if (AuthService.isOfficeAdmin()) {
      const user = await UsersService.getOne(AuthService.getUserId());
      if (user.administeredOffices.length > 1) {
        whereClauses.push(
          where("officeLocation", "in", user.administeredOffices),
        );
      } else {
        whereClauses.push(where("officeLocation", "==", user.officeLocation));
      }
    }
    return query(this.getCollection(), ...whereClauses);
  }

  /**
   * @async
   * @param {boolean} skipThirdParty
   * @returns {Promise<Asset[]>}
   */
  async getAssets(skipThirdParty = false) {
    let assets,
      jetBrainsAssets = [];

    if (AuthService.isAdmin()) {
      assets = await this.getAll();
      if (!skipThirdParty) {
        jetBrainsAssets = await JetbrainsAssetsService.getAllAssets(
          AuthService.getUserId(),
        );
      }
    } else {
      assets = await this.getCurrentUserAssets();
      if (!skipThirdParty) {
        jetBrainsAssets = await JetbrainsAssetsService.getAllAssets(
          AuthService.getUserId(),
          { role: UserRole.USER },
        );
      }
    }

    return [...assets, ...jetBrainsAssets];
  }

  /**
   * @async
   * @param {string} userId
   */
  async getUserAssets(userId) {
    if (AuthService.isSuperAdmin()) {
      return await this.getAllWhere(
        "userRef",
        "==",
        UsersService.getDocById(userId),
      );
    } else if (AuthService.isOfficeAdmin()) {
      const user = await UsersService.getOne(AuthService.getUserId());
      if (user.administeredOffices.length > 1) {
        return this.getWithWhereClauses([
          where("userRef", "==", UsersService.getDocById(userId)),
          where("officeLocation", "in", user.administeredOffices),
        ]);
      } else {
        return this.getWithWhereClauses([
          where("userRef", "==", UsersService.getDocById(userId)),
          where("officeLocation", "==", user.officeLocation),
        ]);
      }
    } else {
      if (userId !== AuthService.getUserId()) {
        return [];
      }
      return this.getCurrentUserAssets();
    }
  }

  /**
   * @async
   * @returns {Promise<Asset[]>}
   */
  async getCurrentUserAssets() {
    const userLocation = await UsersService.getUserOffice();
    return await this.getWithWhereClauses([
      or(
        where(
          "userRef",
          "==",
          UsersService.getDocById(AuthService.getUserId()),
        ),
        and(
          where("isOfficeAsset", "==", true),
          where("officeLocation", "==", userLocation),
        ),
      ),
    ]);
  }

  /**
   * @async
   */
  async getLoaners() {
    if (AuthService.isSuperAdmin()) {
      return this.getAllWhere("loaner", "==", true);
    } else if (AuthService.isOfficeAdmin()) {
      const user = await UsersService.getOne(AuthService.getUserId());
      if (user.administeredOffices.length > 1) {
        return this.getWithWhereClauses([
          where("loaner", "==", true),
          where("officeLocation", "in", user.administeredOffices),
        ]);
      } else {
        return this.getWithWhereClauses([
          where("loaner", "==", true),
          where("officeLocation", "==", user.officeLocation),
        ]);
      }
    } else {
      const officeLocation = await UsersService.getUserOffice();
      return this.getWithWhereClauses([
        where("loaner", "==", true),
        where("officeLocation", "==", officeLocation),
      ]);
    }
  }
}
