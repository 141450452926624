const alertRef = {
  message: null,
  type: "is-primary",
};

const SECOND_IN_MILLISECONDS = 1000;

export const AlertService = {
  getRef() {
    return alertRef;
  },

  setRef(message, type) {
    Object.assign(alertRef, { message, type });
  },

  /**
   * Display an alert message in the bottom right corner of the application.
   * By default it closes after 5 seconds.
   *
   * If the alert should persist (not automatically close) pass null to the timeout parameter.
   * @param {string} message
   * @param {number} timeout display time in seconds
   */
  alert(message, timeout = 5, type = "is-primary") {
    this.setRef(message, type);
    if (timeout) {
      setTimeout(() => {
        this.close();
      }, timeout * SECOND_IN_MILLISECONDS);
    }
  },

  /**
   * Manually close the message
   */
  close() {
    this.setRef(null, null);
  },
};
