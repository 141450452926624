import { getDocs, query, where } from "firebase/firestore";
// eslint-disable-next-line no-unused-vars
import { UsersFilter } from "@/models/UsersFilter";
import { User, UserRole } from "@/models/documentModels/User";
import { AuthService } from "@/services/AuthService";
import { FirestoreService } from "@/services/FirestoreService";

export class UsersFirestoreService extends FirestoreService {
  /**
   * @override
   * @returns {firestore.Query<firestore.DocumentData>}
   */
  async getAllQuery() {
    const whereClauses = [];
    if (AuthService.isUser()) {
      whereClauses.push(where("__name__", "==", AuthService.getUserId()));
    } else if (AuthService.isOfficeAdmin()) {
      const user = await this.getOne(AuthService.getUserId());
      if (user.administeredOffices.length > 1) {
        whereClauses.push(
          where("officeLocation", "in", user.administeredOffices),
        );
      } else {
        whereClauses.push(where("officeLocation", "==", user.officeLocation));
      }
    }
    return query(this.getCollection(), ...whereClauses);
  }

  /**
   * @override
   * @returns {Promise<C[]>}
   */
  async getAll() {
    if (AuthService.isUser()) {
      return await this.getAllWhere("__name__", "==", AuthService.getUserId());
    } else if (AuthService.isOfficeAdmin()) {
      const user = await this.getOne(AuthService.getUserId());
      if (user.administeredOffices.length > 1) {
        return await this.getAllWhere(
          "officeLocation",
          "in",
          user.administeredOffices,
        );
      } else {
        return await this.getAllWhere(
          "officeLocation",
          "==",
          user.officeLocation,
        );
      }
    }
    const resolvedDocs = await getDocs(this.getCollection());
    return this.toDocClasses(resolvedDocs).filter((doc) => !doc.isDeleted);
  }

  async getLeaders() {
    if (AuthService.isSuperAdmin()) {
      return this.getAllWhere("role", "in", [
        UserRole.ADMIN,
        UserRole.OFFICEADMIN,
      ]);
    } else if (AuthService.isOfficeAdmin()) {
      const user = await this.getOne(AuthService.getUserId());
      if (user.administeredOffices.length > 1) {
        return this.getWithWhereClauses([
          where("officeLocation", "in", user.administeredOffices),
          where("role", "in", [UserRole.ADMIN, UserRole.OFFICEADMIN]),
        ]);
      } else {
        return this.getWithWhereClauses([
          where("officeLocation", "==", user.officeLocation),
          where("role", "in", [UserRole.ADMIN, UserRole.OFFICEADMIN]),
        ]);
      }
    } else {
      return [];
    }
  }

  /**
   *
   * @param {User} requestedUser
   */
  async getOfficeLeaders(requestedUser) {
    if (AuthService.isSuperAdmin()) {
      return await this.getWithWhereClauses([
        where("administeredOffices", "array-contains-any", [
          requestedUser.officeLocation,
        ]),
        where("disabled", "!=", true),
        where("role", "in", [UserRole.ADMIN, UserRole.OFFICEADMIN]),
      ]);
    } else if (AuthService.isOfficeAdmin()) {
      const user = await this.getOne(AuthService.getUserId());
      if (
        (user.administeredOffices.length > 1 &&
          user.administeredOffices.includes(requestedUser.officeLocation)) ||
        (user.administeredOffices.length <= 1 &&
          user.officeLocation === requestedUser.officeLocation)
      ) {
        return await this.getWithWhereClauses([
          where("administeredOffices", "array-contains-any", [
            requestedUser.officeLocation,
          ]),
          where("disabled", "!=", true),
          where("role", "in", [UserRole.ADMIN, UserRole.OFFICEADMIN]),
        ]);
      }
    } else {
      return [];
    }
  }

  /**
   *
   * @param {String} leaderId
   */
  async getUsersByLeaderId(leaderId) {
    return this.getAllWhere("leader", "==", this.getDocById(leaderId));
  }

  /**
   * @async
   * @param {string} email
   * @returns {Promise<User>} User
   */
  async getUserByEmail(email) {
    const q = query(this.getCollection(), where("email", "==", email));
    const resolvedDocs = await getDocs(q);

    if (resolvedDocs.size === 1) {
      return this.toDocClass(resolvedDocs.docs[0]);
    }

    return new User();
  }

  /**
   * @async
   * @param {string} email
   * @returns {Promise<firestore.DocumentReference<firestore.DocumentData> | null>} DocumentReference or null
   */
  async getUserDocByEmail(email) {
    const q = query(this.getCollection(), where("email", "==", email));
    const resolvedDocs = await getDocs(q);

    if (resolvedDocs.size === 1) {
      return resolvedDocs.docs[0].ref;
    }

    return null;
  }

  /**
   *
   * @param {UsersFilter} filter
   */
  async getAllFromUsersFilter(filter) {
    const whereClauses = [];

    if (AuthService.isUser()) {
      whereClauses.push(where("__name__", "==", AuthService.getUserId()));
    } else if (AuthService.isOfficeAdmin()) {
      const user = await this.getOne(AuthService.getUserId());
      if (user.administeredOffices.length > 1) {
        whereClauses.push(
          where("officeLocation", "in", user.administeredOffices),
        );
      } else {
        whereClauses.push(where("officeLocation", "==", user.officeLocation));
      }
    }

    if (filter.disabled) {
      whereClauses.push(where("disabled", "==", true));
    } else {
      whereClauses.push(where("disabled", "==", false));
    }

    if (filter.location.length > 0) {
      const officeObjectArray = filter.location.map((office) => office.toRef());
      whereClauses.push(where("officeLocation", "in", officeObjectArray));
    }

    if (filter.role) {
      whereClauses.push(where("role", "==", filter.role));
    }

    if (filter.leader) {
      if (filter.leader === "unassigned") {
        whereClauses.push(where("leader", "==", null));
      } else {
        whereClauses.push(
          where("leader", "==", this.getDocById(filter.leader)),
        );
      }
    }

    let users = await this.getWithWhereClauses(whereClauses);

    // ? Perform substring comparison in the client as Firestore do not support this natively.
    if (filter.searchTerm) {
      users = users.filter((user) => {
        const isDisplayNameMatch = user.displayName
          .toLowerCase()
          .includes(filter.searchTerm.toLowerCase());

        const isEmailMatch = user.email
          .toLowerCase()
          .includes(filter.searchTerm.toLowerCase());

        return isDisplayNameMatch || isEmailMatch;
      });
    }

    return users;
  }

  async getUserOffice() {
    const user = await this.getOne(AuthService.getUserId());
    return user.officeLocation;
  }
}
