import { getDocs, query } from "firebase/firestore";
import { Office } from "@/models/documentModels/Office";
import { UserRole } from "@/models/documentModels/User";
import { AuthService } from "@/services/AuthService";
import { FirestoreService } from "@/services/FirestoreService";
import { UsersService } from "@/services/UsersService";

export class OfficeGroupsFirestoreService extends FirestoreService {
  /**
   @override
   * @returns {firestore.Query<firestore.DocumentData>}
   */
  async getAllQuery() {
    return query(this.getCollection());
  }

  /**
   * @override
   * @returns {Promise<C[]>}
   */
  async getAll() {
    const resolvedDocs = await getDocs(this.getCollection());
    return this.toDocClasses(resolvedDocs).filter((doc) => !doc.isDeleted);
  }

  /**
   * Gets all office-groups in which user is administering for all offices
   * within that office-group
   */
  async getAdministeringOfficeGroups() {
    if (AuthService.isSuperAdmin()) {
      return this.getAll();
    } else if (AuthService.isOfficeAdmin()) {
      const user = await UsersService.getOne(AuthService.getUserId());
      let administeredOffices = [];
      if (user.administeredOffices.length > 1) {
        administeredOffices = await user.resolveListOfRefs(
          Office,
          "administeredOffices",
        );
      } else {
        administeredOffices = [await user.resolveRef(Office, "officeLocation")];
      }
      const allOfficeGroups = await this.getAll();
      const resultOfficeGroups = [];
      for (const officeGroup of allOfficeGroups) {
        const officeGroupOffices = await officeGroup.resolveListOfRefs(
          Office,
          "offices",
        );
        if (
          officeGroupOffices.every((go) =>
            administeredOffices.find((ao) => ao.id === go.id),
          )
        ) {
          resultOfficeGroups.push(officeGroup);
        }
      }
      return resultOfficeGroups;
    } else {
      return [];
    }
  }

  /**
   * Delete an office-group, only if user is super admin
   *
   * @param {string} id
   */
  async deleteOne(id) {
    if (AuthService.getUserRole() === UserRole.ADMIN) {
      await super.deleteOne(id);
    }
  }
}
