const modalRef = {
  component: null,
  args: null,
  listeners: null,
};

export const ModalService = {
  getRef() {
    return modalRef;
  },

  setRef(component, args = null, listeners = null) {
    Object.assign(modalRef, { component, args, listeners });
  },

  open(component, args = null, listeners = null) {
    this.setRef(component, args, listeners);
  },

  close() {
    this.setRef(null, null, null);
  },
};
