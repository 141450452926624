<template>
  <transition>
    <div id="the-alert-container" v-if="alertRef.message">
      <div class="notification" v-bind:class="notificationClasses">
        <button class="delete" @click="onClickCloseAlert" />
        {{ alertRef.message }}
      </div>
    </div>
  </transition>
</template>

<script>
import { AlertService } from "@/services/AlertService";
export default {
  name: "TheAlertContainer",
  data() {
    return {
      alertRef: AlertService.getRef(),
    };
  },
  computed: {
    notificationClasses() {
      return [this.alertRef.type];
    },
  },
  methods: {
    onClickCloseAlert() {
      AlertService.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.notification {
  bottom: 4.5rem;
  margin-left: 1rem;
  position: fixed;
  right: 1rem;
  white-space: pre;
  box-shadow: black 0 0 25px 0;
  z-index: 1000;
}
</style>
