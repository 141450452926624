<template>
  <div id="the-modal-container" v-if="modalRef.component">
    <component
      :is="modalRef.component"
      v-bind="modalRef.args"
      v-on="modalRef.listeners"
    />
  </div>
</template>

<script>
import { ModalService } from "@/services/ModalService";
export default {
  name: "TheModalContainer",
  data() {
    return {
      modalRef: ModalService.getRef(),
    };
  },
};
</script>
