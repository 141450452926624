import {
  getAuth,
  getRedirectResult,
  GoogleAuthProvider,
  signInWithRedirect,
  signOut,
} from "firebase/auth";
import "@/firebase";
import { UserRole } from "@/models/documentModels/User";

export const AuthService = new (class {
  /**
   * @type {{role: UserRole | null}}
   * @private
   */
  _state = {
    role: null,
  };

  /**
   * @readonly
   */
  getState() {
    return this._state;
  }

  /**
   * Reset all state to its initial values.
   * @returns {void}
   */
  resetState() {
    Object.assign(this._state, {
      role: null,
    });
  }

  /**
   * @returns {void}
   */
  async signInWithRedirect() {
    const provider = new GoogleAuthProvider();

    provider.setCustomParameters({ hd: "softhouse.se" });

    provider.addScope("email");
    provider.addScope("profile");
    provider.addScope("https://www.googleapis.com/auth/user.organization.read");

    const auth = getAuth();
    await signInWithRedirect(auth, provider);
  }

  /**
   * @returns {Promise<void>}
   */
  async logout() {
    await signOut(getAuth());
  }

  /**
   * @returns {firebase.User}
   */
  getUser() {
    return getAuth().currentUser;
  }

  /**
   * @returns {UserRole} role
   */
  getUserRole() {
    return this._state.role;
  }

  /**
   * @returns {string} user id
   */
  getUserId() {
    return getAuth().currentUser?.uid || "invalid user";
  }

  async getRedirectResult() {
    return await getRedirectResult(getAuth());
  }

  /**
   * Update the internal state.
   * @returns {void}
   */
  updateUserState(role) {
    if (Object.values(UserRole).includes(role)) {
      this._state.role = role;
    }
  }

  /**
   * If the user is any kind of Admin this will trigger.
   *
   * @returns {boolean}
   */
  isAdmin() {
    return (
      this.getUserRole() === UserRole.ADMIN ||
      this.getUserRole() === UserRole.OFFICEADMIN
    );
  }

  /**
   * If the user is super Admin this will trigger.
   *
   * @returns {boolean}
   */
  isSuperAdmin() {
    return this.getUserRole() === UserRole.ADMIN;
  }

  /**
   * If the user is office Admin this will trigger.
   *
   * @returns {boolean}
   */
  isOfficeAdmin() {
    return this.getUserRole() === UserRole.OFFICEADMIN;
  }

  /**
   * If the user role is null the user will be treated as a user.
   *
   * @returns {boolean}
   */
  isUser() {
    return this.getUserRole() === null || this.getUserRole() === UserRole.USER;
  }
})();
