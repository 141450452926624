import axios from "axios";
import { GoogleAuthProvider } from "firebase/auth";
import {
  collection,
  getDoc,
  getDocs,
  getFirestore,
  onSnapshot,
} from "firebase/firestore";
import "@/firebase";
import { CollectionNames } from "@/models/CollectionNames";
import { Office } from "@/models/documentModels/Office";
// eslint-disable-next-line no-unused-vars
import { UserRole } from "@/models/documentModels/User";
import { AuthService } from "@/services/AuthService";
import Log from "@/services/Log";
import { OfficesService } from "@/services/OfficesService";
import { UsersService } from "@/services/UsersService";

export const AuthUtils = new (class {
  /**
   * @readonly
   * @type {number}
   */
  WAIT_FOR_USER_ROLE_LIMIT_MS = 10000;

  /**
   * @private
   */
  unsubscribeUserChangeListener = null;

  /**
   * @returns {Promise<void>}
   */
  async logout() {
    if (this.unsubscribeUserChangeListener) {
      this.unsubscribeUserChangeListener();
      this.unsubscribeUserChangeListener = null;
    }
    await AuthService.logout();
  }

  async getOffices() {
    return await getDocs(collection(getFirestore(), CollectionNames.OFFICE));
  }

  async addNewOfficeLocation(officeHRV) {
    let officeObject = new Office({
      HRV: officeHRV.charAt(0).toUpperCase() + officeHRV.slice(1),
      CRV: officeHRV
        .toUpperCase()
        .normalize("NFKD")
        .replace(/[\u0300-\u036F]/g, "")
        .replace(/\W/g, ""),
    });
    return OfficesService.createOne(officeObject);
  }

  /**
   * Retrieves the users location from Google.
   * @param {string} accessToken
   * @returns {Promise<string>}
   */
  async retrieveUserOfficeLocation(accessToken) {
    if (accessToken === "FirebaseAuthEmulatorFakeAccessToken_google.com") {
      return "Karlskrona";
    }
    const instance = axios.create();
    const response = await instance.get("people/me", {
      baseURL: "https://people.googleapis.com/v1/",
      timeout: 1000,
      headers: {
        authorization: "Bearer " + accessToken,
        accept: "application/json",
      },
      params: {
        personFields: "organizations",
      },
    });
    return response.data.organizations[0].location;
  }

  /**
   * Updates the logged-in users location.
   * @param {string} accessToken
   * @returns {void}
   */
  async updateUserLocation(accessToken) {
    const userDoc = await this.getUserDoc();
    if (!userDoc.data()?.email) {
      return;
    }
    const user = UsersService.toDocClass(userDoc);
    let officeString = await this.retrieveUserOfficeLocation(accessToken);
    if (!officeString) {
      Log.error("User location was unexpectedly undefined");
      return;
    }

    let offices = await this.getOffices();
    let office = offices.docs.find(
      (doc) => doc.data().HRV.toLowerCase() === officeString.toLowerCase(),
    );
    if (office) {
      user.officeLocation = office.ref;
    } else {
      user.officeLocation = await this.addNewOfficeLocation(officeString);
    }
    Log.debug("Assigned user to office", officeString);
    await UsersService.updateOne(user);
  }

  async getUserDoc() {
    return await getDoc(UsersService.getDocById(AuthService.getUserId()));
  }

  /**
   * Catches the login redirect from Firebase Auth.
   * @returns {Promise<void>}
   */
  async catchRedirect() {
    const result = await AuthService.getRedirectResult();
    if (result && result.user !== null) {
      Log.debug("Caught auth redirect");
      const user = await UsersService.getOne(AuthService.getUserId());
      if (!user?.officeLocation) {
        const credential = GoogleAuthProvider.credentialFromResult(result);
        await this.updateUserLocation(credential.accessToken);
      }
    }
  }

  /**
   * Sync the local user state with the current user role.
   * @returns {Promise<void>}
   */
  async syncLocalUserState() {
    const { claims } = await AuthService.getUser().getIdTokenResult(true);
    AuthService.updateUserState(claims.role);
  }

  /**
   * Checks if the role passed to this function differs
   * from that currently assigned to the user.
   *
   * @param {UserRole} newRole
   * @returns {Promise<void>}
   */
  async checkUserRole(newRole) {
    if (!newRole) {
      return;
    }

    const currentRole = AuthService.getUserRole();
    if (currentRole === null) {
      Log.debug(`Syncing User role ${newRole} to state`);
      await this.syncLocalUserState();
    } else if (newRole !== currentRole) {
      Log.debug(`User role has changed from ${currentRole} to ${newRole}`);
      await this.syncLocalUserState();
      window.location.reload();
    }
  }

  startUserChangeListener() {
    if (!this.unsubscribeUserChangeListener) {
      this.unsubscribeUserChangeListener = this.userChangeListener();
    }
  }

  userChangeListener() {
    const getDoc = UsersService.getDocById(AuthService.getUserId());
    return onSnapshot(getDoc, async (user) => {
      if (!user.data()?.email) {
        return;
      }

      await this.catchRedirect();
      await this.checkUserRole(user.data()?.role);
    });
  }

  /**
   * Waits until the user has been created in firestore and
   * the local state has been updated with the UserRole.
   *
   * @returns Promise
   */
  waitUntilUserHasRole() {
    this.startUserChangeListener();
    return new Promise((resolve, reject) => {
      const timeoutMs = 200;
      let timePassedMs = 0;
      const intervalId = setInterval(() => {
        if (AuthService.getUserRole() !== null) {
          clearInterval(intervalId);
          resolve();
        }
        if (timePassedMs >= this.WAIT_FOR_USER_ROLE_LIMIT_MS) {
          clearInterval(intervalId);
          Log.error(
            "User still doesn't have a Role. " +
              "Either the network is really slow or something went wrong when creating the user. " +
              "Wait for a few more seconds and then try to reload the page.",
          );
          reject(new Error("Waiting for User Role timed out"));
        }
        timePassedMs += timeoutMs;
      }, timeoutMs);
    });
  }
})();
