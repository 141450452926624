/**
 * @param {HTMLElement} el
 * @param {string} className
 */
export function addClass(el, className) {
  el.classList.add(className);
}

/**
 * @param {HTMLElement} el
 * @param {string} className
 */
export function removeClass(el, className) {
  el.classList.remove(className);
}
