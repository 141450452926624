/**
 * Returns the Human Readable Value(HRV) for a given type.
 *
 * @param {ResourceType} type
 */
export function getHRVForResourceType(type) {
  const values = {
    [ResourceType.COMPUTER]: "Computer",
    [ResourceType.MOBILE_PHONE]: "Mobile phone",
    [ResourceType.LICENSE]: "License",
    [ResourceType.HEADSET]: "Headset",
    [ResourceType.PERIPHERAL]: "Peripheral",
    [ResourceType.UNKNOWN]: "Unknown",
  };

  return values[type];
}

/**
 /* @param {string} type
 */
export function getIconForResourceType(type) {
  switch (type) {
    case ResourceType.COMPUTER:
      return "clarity:computer-line";
    case ResourceType.MOBILE_PHONE:
      return "circum:mobile-3";
    case ResourceType.LICENSE:
      return "mage:compact-disk";
    case ResourceType.HEADSET:
      return "ion:headset-outline";
    case ResourceType.PERIPHERAL:
      return "fluent:connector-20-regular";
    case ResourceType.UNKNOWN:
    default:
      return "octicon:rocket-24";
  }
}

/**
 * @enum {string} ResourceType
 */
export const ResourceType = {
  COMPUTER: "COMPUTER",
  MOBILE_PHONE: "MOBILE_PHONE",
  LICENSE: "LICENSE",
  HEADSET: "HEADSET",
  PERIPHERAL: "PERIPHERAL",
  UNKNOWN: "UNKNOWN",
};

/**
 * @class Resource
 */
export class Resource {
  /**
   * @param {{
   *  id: string;
   *  isDeleted: boolean;
   *  type: ResourceType;
   *  manufacturer: string
   *  model: string;
   *  officeLocation: firestore.DocumentReference<firestore.DocumentData>
   * }} parameters
   */
  constructor({
    id = "",
    isDeleted = false,
    type = null,
    manufacturer = "",
    model = "",
    officeLocation,
  } = {}) {
    this.id = id;
    this.isDeleted = isDeleted;
    this.type = type;
    this.manufacturer = manufacturer;
    this.model = model;
    this.officeLocation = officeLocation;
  }

  toString() {
    return [this.manufacturer, this.model]
      .filter((value) => value !== "" && value !== null && value !== undefined)
      .join(" ");
  }
}
