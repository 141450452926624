import { getDocs, query } from "firebase/firestore";
import { UserRole } from "@/models/documentModels/User";
import { AuthService } from "@/services/AuthService";
import { FirestoreService } from "@/services/FirestoreService";

export class OfficesFirestoreService extends FirestoreService {
  /**
   @override
   * @returns {firestore.Query<firestore.DocumentData>}
   */
  async getAllQuery() {
    return query(this.getCollection());
  }

  /**
   * @override
   * @returns {Promise<C[]>}
   */
  async getAll() {
    const resolvedDocs = await getDocs(this.getCollection());
    return this.toDocClasses(resolvedDocs).filter((doc) => !doc.isDeleted);
  }

  /**
   * Delete an office-group, only if user is super admin
   * Will clear every reference to the office in other tables
   *
   * @param {string} id
   * @returns {Promise<void>}
   */
  async deleteOne(id) {
    if (AuthService.getUserRole() === UserRole.ADMIN) {
      await super.deleteOne(id);
    }
  }
}
