import { CacheService } from "@/services/CacheService";

/**
 * @param {User} user
 */
export function createAvatarSource({ displayName, photoUrl }) {
  const svgName = displayName.split(" ").join("");
  return photoUrl ?? `https://api.adorable.io/avatars/256/${svgName}.svg`;
}

/**
 * @async
 * @param {string} employeeSearch
 * @param {C[]} docClasses
 * @returns {Promise<C[]>} C[]
 */
export async function filterByUserRef(employeeSearch, docClasses) {
  const userRefs = docClasses.map((a) => a.userRef);
  let userRefsAsString = await getUserReferences(userRefs);
  const users = await filterUserByInput(employeeSearch);
  const userIds = users.map((user) => user.id);

  userRefsAsString = userRefsAsString.filter((userRef) => {
    return userIds.includes(userRef);
  });

  return docClasses
    .map((doc) => {
      if (doc.userRef === null) {
        doc.userRef = "";
      }
      return doc;
    })
    .filter((doc) => {
      return userRefsAsString.includes(doc.userRef.id);
    });
}

/**
 * @private
 * @async
 * @param {[*]} userRefs
 * @returns {Promise<string[]>}
 */
async function getUserReferences(userRefs) {
  return userRefs.map((a) => CacheService.getUser(a)).map((u) => u.id);
}

/**
 * @private
 * @async
 * @param {string} employeeName
 * @returns {Promise<User[]>}
 */
async function filterUserByInput(employeeName) {
  const users = CacheService.getUsers();

  return users.filter((user) => {
    const isDisplayNameMatch = user.displayName
      .toLowerCase()
      .includes(employeeName);

    const isEmailMatch = user.email.toLowerCase().includes(employeeName);

    return isDisplayNameMatch || isEmailMatch;
  });
}
