<template>
  <div class="icon-container">
    <icon
      v-bind:icon="icon"
      height="none"
      v-bind:style="{ width: `${size}px`, height: `${size}px` }"
      v-bind:inline="true"
    />
  </div>
</template>

<script>
import { Icon } from "@iconify/vue2";

export default {
  name: "TheIcon",
  components: { Icon },
  props: {
    icon: String,
    size: {
      type: [Number, String],
      required: false,
      default: 24,
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-container {
  display: inline;
  align-items: center;
  text-align: center;
}
</style>
