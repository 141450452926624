/**
 * Statically loaded configuration from .env files.
 * For .env variables to become visible they need to start with "VUE_APP_" (https://cli.vuejs.org/guide/mode-and-env.html#environment-variables)
 *
 * @type {{
 *  REGION: string
 * }}
 */
const Config = {
  REGION: process.env.VUE_APP_REGION,
};

export default Config;
