<template>
  <nav
    class="navbar is-dark is-fixed-bottom"
    role="navigation"
    aria-label="main navigation"
  >
    <b-sidebar
      v-if="isAdmin"
      type="is-dark"
      fullheight
      overlay
      v-model="isNavbarOpen"
    >
      <div class="side-nav-content">
        <div class="side-nav-header">
          <router-link
            class="navbar-item height-100"
            v-bind:to="{ name: 'Assets' }"
          >
            <div
              class="height-100 is-flex is-align-items-center is-justify-content-center"
            >
              <figure class="image is-app-logo is-navbar-logo">
                <heaven-logo />
              </figure>
              <span>Heaven</span>
            </div>
          </router-link>
        </div>

        <span class="width-100 is-flex">Admin</span>
        <div v-if="isSuperAdmin" class="is-flex">
          <div class="is-flex-grow-1">
            <router-link
              class="navbar-item"
              v-bind:to="{ name: 'OfficeGroups' }"
              exact-active-class="is-active"
            >
              Office groups
            </router-link>
          </div>
          <div class="add-button-wrapper">
            <add-office-group-button />
          </div>
        </div>
        <div class="is-flex">
          <div class="is-flex-grow-1">
            <router-link
              class="navbar-item"
              v-bind:to="{ name: 'Offices' }"
              exact-active-class="is-active"
            >
              Offices
            </router-link>
          </div>
          <div v-if="isSuperAdmin" class="add-button-wrapper">
            <add-office-button />
          </div>
        </div>
        <div class="is-flex">
          <div class="is-flex-grow-1">
            <router-link
              class="navbar-item"
              v-bind:to="{ name: 'Retailers' }"
              exact-active-class="is-active"
            >
              Retailers
            </router-link>
          </div>
          <div class="add-button-wrapper">
            <add-retailer-button />
          </div>
        </div>
        <div class="is-flex">
          <div class="is-flex-grow-1">
            <router-link
              class="navbar-item"
              v-bind:to="{ name: 'Resources' }"
              exact-active-class="is-active"
            >
              Resources
            </router-link>
          </div>
          <div class="add-button-wrapper">
            <add-resource-button />
          </div>
        </div>

        <span class="width-100 is-flex">Inventory</span>
        <router-link
          class="navbar-item"
          v-bind:to="{ name: 'Library' }"
          exact-active-class="is-active"
        >
          Library
        </router-link>
        <div class="is-flex">
          <div class="is-flex-grow-1">
            <router-link
              class="navbar-item"
              v-bind:to="{ name: 'Assets' }"
              exact-active-class="is-active"
            >
              Assets
            </router-link>
          </div>
          <div class="add-button-wrapper">
            <add-asset-button />
          </div>
        </div>

        <span class="width-100 is-flex">Access</span>
        <div class="is-flex">
          <div class="is-flex-grow-1">
            <router-link
              class="navbar-item"
              v-bind:to="{ name: 'Keys' }"
              exact-active-class="is-active"
            >
              Keys
            </router-link>
          </div>
          <div class="add-button-wrapper">
            <add-key-button />
          </div>
        </div>
        <router-link
          class="navbar-item"
          v-bind:to="{ name: 'Users' }"
          exact-active-class="is-active"
        >
          Users
        </router-link>

        <router-link
          class="navbar-item"
          v-bind:to="{ name: 'SupportRequests' }"
          exact-active-class="is-active"
        >
          Support requests
          <number-of-notifications />
        </router-link>

        <router-link
          class="navbar-item"
          v-bind:to="{ name: 'User', params: { userId } }"
          exact-active-class="is-active"
        >
          Profile
        </router-link>

        <router-link
          class="navbar-item"
          v-bind:to="{ name: 'Dashboard' }"
          exact-active-class="is-active"
        >
          Dashboard
        </router-link>
      </div>
    </b-sidebar>

    <div class="navbar-brand is-app-navbar">
      <!-- ? Div is hidden on Desktop devices. -->
      <div class="navbar-item is-touch-navbar-item is-hidden-desktop">
        <div class="tabs is-fullwidth is-toggle">
          <div v-if="isAdmin" class="is-flex is-flex-direction-row width-100">
            <div
              class="is-flex is-flex-direction-column is-align-items-center mx-1"
              @click="onClickNavbarBurger"
              :class="{ 'is-active': isNavbarOpen }"
            >
              <a href="javascript:">
                <span class="icon m-0">
                  <the-icon icon="mingcute:menu-fill" size="30" />
                </span>
              </a>
            </div>
          </div>
          <div v-else class="is-flex is-flex-direction-row width-100">
            <div
              class="is-flex is-flex-direction-column is-align-items-center mx-1"
            >
              <router-link v-bind:to="{ name: 'Library' }">
                <figure class="icon m-0">
                  <heaven-logo />
                </figure>
              </router-link>
            </div>

            <div
              class="is-flex is-flex-direction-column is-flex-grow-1 is-align-items-flex-end"
            >
              <div
                class="is-flex is-flex-direction-row is-align-items-center my-auto"
              >
                <div class="is-flex-direction-column mx-1">
                  <router-link
                    class="navbar-item"
                    v-bind:to="{ name: 'Library' }"
                    exact-active-class="is-active"
                  >
                    <span class="icon m-0">
                      <the-icon icon="ion:library" size="30" />
                    </span>
                  </router-link>
                </div>
                <div class="is-flex-direction-column mx-1">
                  <router-link
                    class="navbar-item"
                    v-bind:to="{ name: 'User', params: { userId } }"
                    exact-active-class="is-active"
                  >
                    <span class="icon m-0">
                      <the-icon icon="raphael:user" size="30" />
                    </span>
                  </router-link>
                </div>
                <div class="is-flex-direction-column mx-1">
                  <add-asset-button />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- ? Div is hidden on Touch devices. -->
    <div v-if="isAdmin" class="navbar-menu is-hidden-touch">
      <router-link class="navbar-item" v-bind:to="{ name: 'Assets' }">
        <figure class="image is-app-logo is-navbar-logo">
          <heaven-logo />
        </figure>
        <span>Heaven</span>
      </router-link>

      <div class="navbar-end">
        <b-dropdown
          position="is-top-right"
          v-bind:close-on-click="false"
          max-height="100vh"
          aria-role="list"
        >
          <template #trigger="{ active }">
            <a
              href="javascript:"
              class="navbar-item"
              v-bind:class="{ 'is-focused': active }"
            >
              <span>Admin</span>
            </a>
          </template>
          <b-dropdown-item v-if="isSuperAdmin" has-link aria-role="listitem">
            <div class="is-flex">
              <div class="is-flex-grow-1">
                <router-link
                  v-bind:to="{ name: 'OfficeGroups' }"
                  exact-active-class="is-active"
                >
                  Office groups
                </router-link>
              </div>
              <div class="add-button-wrapper">
                <add-office-group-button />
              </div>
            </div>
          </b-dropdown-item>
          <b-dropdown-item has-link aria-role="listitem">
            <div class="is-flex">
              <div class="is-flex-grow-1">
                <router-link
                  v-bind:to="{ name: 'Offices' }"
                  exact-active-class="is-active"
                >
                  Offices
                </router-link>
              </div>
              <div v-if="isSuperAdmin" class="add-button-wrapper">
                <add-office-button />
              </div>
            </div>
          </b-dropdown-item>
          <b-dropdown-item has-link aria-role="listitem">
            <div class="is-flex">
              <div class="is-flex-grow-1">
                <router-link
                  v-bind:to="{ name: 'Retailers' }"
                  exact-active-class="is-active"
                >
                  Retailers
                </router-link>
              </div>
              <div class="add-button-wrapper">
                <add-retailer-button />
              </div>
            </div>
          </b-dropdown-item>
          <b-dropdown-item has-link aria-role="listitem">
            <div class="is-flex">
              <div class="is-flex-grow-1">
                <router-link
                  v-bind:to="{ name: 'Resources' }"
                  exact-active-class="is-active"
                >
                  Resources
                </router-link>
              </div>
              <div class="add-button-wrapper">
                <add-resource-button />
              </div>
            </div>
          </b-dropdown-item>
        </b-dropdown>

        <b-dropdown
          position="is-top-right"
          v-bind:close-on-click="false"
          max-height="100vh"
          aria-role="list"
        >
          <template #trigger="{ active }">
            <a
              href="javascript:"
              class="navbar-item"
              v-bind:class="{ 'is-focused': active }"
            >
              <span>Inventory</span>
            </a>
          </template>
          <b-dropdown-item has-link aria-role="listitem">
            <router-link
              v-bind:to="{ name: 'Library' }"
              exact-active-class="is-active"
            >
              Library
            </router-link>
          </b-dropdown-item>
          <b-dropdown-item has-link aria-role="listitem">
            <div class="is-flex">
              <div class="is-flex-grow-1">
                <router-link
                  v-bind:to="{ name: 'Assets' }"
                  exact-active-class="is-active"
                >
                  Assets
                </router-link>
              </div>
              <div class="add-button-wrapper">
                <add-asset-button />
              </div>
            </div>
          </b-dropdown-item>
        </b-dropdown>

        <b-dropdown
          position="is-top-right"
          v-bind:close-on-click="false"
          max-height="100vh"
          aria-role="list"
        >
          <template #trigger="{ active }">
            <a
              href="javascript:"
              class="navbar-item"
              v-bind:class="{ 'is-focused': active }"
            >
              <span>Access</span>
            </a>
          </template>
          <b-dropdown-item has-link aria-role="listitem">
            <div class="is-flex">
              <div class="is-flex-grow-1">
                <router-link
                  v-bind:to="{ name: 'Keys' }"
                  exact-active-class="is-active"
                >
                  Keys
                </router-link>
              </div>
              <div class="add-button-wrapper">
                <add-key-button />
              </div>
            </div>
          </b-dropdown-item>
          <b-dropdown-item has-link aria-role="listitem">
            <router-link
              v-bind:to="{ name: 'Users' }"
              exact-active-class="is-active"
            >
              Users
            </router-link>
          </b-dropdown-item>
        </b-dropdown>

        <router-link
          class="navbar-item"
          v-bind:to="{ name: 'SupportRequests' }"
          exact-active-class="is-active"
        >
          Support requests
          <number-of-notifications />
        </router-link>

        <router-link
          class="navbar-item"
          v-bind:to="{ name: 'User', params: { userId } }"
          exact-active-class="is-active"
        >
          Profile
        </router-link>

        <router-link
          class="navbar-item"
          v-bind:to="{ name: 'Dashboard' }"
          exact-active-class="is-active"
        >
          Dashboard
        </router-link>

        <div class="navbar-item" />
      </div>
    </div>
    <div v-else class="navbar-menu is-hidden-touch">
      <router-link class="navbar-item" v-bind:to="{ name: 'Library' }">
        <figure class="image is-app-logo is-navbar-logo">
          <heaven-logo />
        </figure>
        <span>Heaven</span>
      </router-link>

      <div class="navbar-end">
        <router-link
          class="navbar-item"
          v-bind:to="{ name: 'Library' }"
          exact-active-class="is-active"
        >
          Library
        </router-link>

        <router-link
          class="navbar-item"
          v-bind:to="{ name: 'User', params: { userId } }"
          exact-active-class="is-active"
        >
          Profile
        </router-link>

        <div class="navbar-item">
          <div class="add-button-wrapper">
            <add-asset-button />
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import HeavenLogo from "@/assets/heaven-logo.svg";
import AddAssetButton from "@/components/assets/AddAssetButton";
import TheIcon from "@/components/general/TheIcon";
import AddKeyButton from "@/components/keys/AddKeyButton";
import AddOfficeGroupButton from "@/components/office-groups/AddOfficeGroupButton";
import AddOfficeButton from "@/components/offices/AddOfficeButton";
import AddResourceButton from "@/components/resources/AddResourceButton";
import AddRetailerButton from "@/components/retailers/AddRetailerButton";
import NumberOfNotifications from "@/components/widget/NumberOfNotifications";
import { AuthService } from "@/services/AuthService";

export default {
  components: {
    AddRetailerButton,
    AddResourceButton,
    AddOfficeButton,
    AddOfficeGroupButton,
    AddKeyButton,
    AddAssetButton,
    NumberOfNotifications,
    TheIcon,
    HeavenLogo,
  },
  name: "TheNavbar",
  data() {
    return {
      isNavbarOpen: false,
    };
  },
  computed: {
    isAdmin() {
      return AuthService.isAdmin();
    },
    isSuperAdmin() {
      return AuthService.isSuperAdmin();
    },
    userId() {
      return AuthService.getUserId();
    },
  },
  methods: {
    onClickNavbarBurger() {
      this.isNavbarOpen = !this.isNavbarOpen;
    },
  },
};
</script>

<style lang="scss" scoped>
.side-nav-content {
  span {
    background-color: #1e1e1e;
    color: #ffffff;
    padding: 1.25rem 1rem;
  }

  a {
    text-decoration: underline;
    background-color: #173044;
    color: #ffffff;
    padding: 1.25rem 1rem;

    &.is-active,
    &:hover {
      background-color: #112331;
      color: #ffffff;
    }
  }
}

.side-nav-header {
  height: 8rem;
  padding-top: 0.125rem;
  border-bottom: 1px solid #ffffff;

  a,
  span {
    text-decoration: none;
    padding: 0;
    background-color: #173044;
    color: #ffffff;

    &.is-active,
    &:hover {
      background-color: #173044;
      color: #ffffff;
    }
  }
}

.navbar .tabs a {
  border: 0;
  color: lightgrey;
  padding-bottom: 0.8rem;
  padding-top: 0.8rem;
}

a.is-focused {
  background-color: #112331;
  color: #ffffff;
}

.navbar a {
  color: #ffffff;

  span {
    padding-bottom: 0.8rem;
    padding-top: 0.8rem;
  }

  &:hover {
    background-color: #112331;
  }

  &:not(&:hover) {
    background-color: #173044;
  }
}

.dropdown + .dropdown {
  margin-left: 0;
}

.dropdown .dropdown-menu .has-link a {
  background-color: #173044;
  color: #ffffff;
  margin-right: 5rem;
  padding: 1.25rem 1rem;

  &.is-active,
  &:hover {
    background-color: #112331;
    color: #ffffff;
  }
}

.add-button-wrapper {
  margin: auto 0;
}
</style>

<style lang="scss">
.navbar-end .dropdown-content {
  background-color: #173044;
}
</style>
