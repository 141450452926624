// eslint-disable-next-line no-unused-vars
import { UserRole } from "@/models/documentModels/User";

export class UsersFilter {
  /**
   *
   * @param {{
   * searchTerm: string;
   * role: UserRole | "";
   * leader: string;
   * disabled: boolean;
   * location: Office[];
   * }} parameters
   */
  constructor({
    searchTerm = "",
    role = "",
    leader = "",
    disabled = false,
    location = [],
  } = {}) {
    this.searchTerm = searchTerm;
    this.role = role;
    this.leader = leader;
    this.disabled = disabled;
    this.location = location;
  }
}
