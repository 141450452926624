import { AuthService } from "@/services/AuthService";
import { CacheService } from "@/services/CacheService";
import { UsersService } from "@/services/UsersService";

export const OfficesMixin = {
  methods: {
    async getAdministeringOffices() {
      if (AuthService.isSuperAdmin()) {
        return CacheService.getOffices();
      } else if (AuthService.isOfficeAdmin()) {
        const user = await UsersService.getOne(AuthService.getUserId());
        if (user.administeredOffices.length > 1) {
          return user.administeredOffices.map((office) =>
            CacheService.getOffice(office),
          );
        } else {
          return [CacheService.getOffice(user.officeLocation)];
        }
      } else {
        return [];
      }
    },
  },
};
