import lodash from "lodash";

/**
 * @param {string | null | undefined} value
 */
export function toTitleCase(value) {
  return value
    ? value.split(" ").map(lodash.capitalize).join(" ").trim()
    : value;
}

/**
 *  Compares two string values by trimming special characters,
 *  i.e. Xhm-3000 will be compared as xhm3000
 *
 *  returns true if both values are equal
 * @param {String} firstValue
 * @param {String} secondValue
 * @returns boolean
 */
export function commonCharactersAreEqual(firstValue, secondValue) {
  if ((!firstValue && secondValue) || (firstValue && !secondValue)) {
    return false;
  }

  const specialCharacters = /[^A-Za-z0-9]/gi;
  const trimFirstValue = firstValue
    .replaceAll(specialCharacters, "")
    .toLowerCase();
  const trimSecondValue = secondValue
    .replaceAll(specialCharacters, "")
    .toLowerCase();

  return trimFirstValue === trimSecondValue;
}
