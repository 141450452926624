class Log {
  /**
   * @private
   */
  debugLogger;
  /**
   * @private
   */
  infoLogger;
  /**
   * @private
   */
  warnLogger;
  /**
   * @private
   */
  errorLogger;

  debug = (message, ...params) => {
    const timestamp = new Date().toLocaleTimeString();
    this.debugLogger(timestamp, message, ...params);
  };

  info = (message, ...params) => {
    const timestamp = new Date().toLocaleTimeString();
    this.infoLogger(timestamp, message, ...params);
  };

  warn = (message, ...params) => {
    const timestamp = new Date().toLocaleTimeString();
    this.warnLogger(timestamp, message, ...params);
  };

  /**
   * @param {string} message
   * @param {Error?} error
   * @param {...string} params
   */
  error = (message, error, ...params) => {
    const timestamp = new Date().toLocaleTimeString();
    this.errorLogger(timestamp, message, ...params);
    error && this.errorLogger(error);
  };

  initiate = () => {
    this.debugLogger = console.log;
    this.infoLogger = console.info;
    this.warnLogger = console.warn;
    this.errorLogger = console.error;
    console.log = this.debug;
    console.debug = this.debug;
    console.info = this.info;
    console.warn = this.warn;
    console.error = this.error;
  };
}

export default new Log();
